import React from 'react';
import {HiOutlineLink} from 'react-icons/hi';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styled from 'styled-components';
import {ImFacebook} from 'react-icons/im';
import {FaTelegramPlane} from 'react-icons/fa';
import {message} from 'antd';
import DemoDownloadBtn from './DemoDownloadBtn';
import {getTrackPath} from '../utils/track-utils';
import CatalogTrackDownloadSelect from '../app/catalog/CatalogTrackDownloadSelect';

const StyledLink = styled.a`
  cursor: pointer;

  svg {
    fill: none;
  }
`;

const StyledButtons = styled.div`
display: flex;
gap:15px;
flex-wrap:wrap;
`;

const Info = ({info}) => {
	return info.map((row) => <li key={row.key}><span>{row.key}</span><span>{row.value}</span></li>);
};

const CollapseContent = ({track, addTrackToBasket, downloadOpen}) => {
	const {id, info, code} = track;

	const link = getTrackPath(`/catalog/?trackId=${code}&type=tracks`);

	const onCopyHandler = (text, result) => {
		if (result) {
			message.success('Ссылка скопирована!');
		}
	};

	return (
		<div
			className="page__player-detailed d-flex js-page-player-detailed"
			style={{height: '100%'}}
		>
			<section className="page__player-detailed-info">
				<h4 className="title title--weight--medium text--middle pb-4">О
					треке
				</h4>
				<ul className="page__player-detailed-info-list js-page-player-detailed-list text--small">
					{info && <Info info={info}/>}
				</ul>
			</section>
			<section className="page__player-detailed-interaction">
				<h4 className="title--weight--medium text--middle pb-4">Приобрести
					трек
				</h4><a
				onClick={addTrackToBasket}
				className="button button--default button--dark waves-light button--waves"
				href="#"
			><span className="button__content">Добавить в корзину</span>
			</a>
				<div className="page__player-detailed-download pt-5">
					<p className="pb-3">Скачать</p>
					<StyledButtons>
						<DemoDownloadBtn track={track}/>
						<CatalogTrackDownloadSelect trackId={track.id} downloadOpen={downloadOpen}/>
					</StyledButtons>
				</div>
				<div className="page__player-detailed-social">
					<p className="pb-3">Поделиться треком</p>
					<ul className="d-inline-flex">
						<li className="player__interaction-item">
							<StyledLink className="player__interaction-button">
								<CopyToClipboard text={link} onCopy={onCopyHandler}>
									<span><HiOutlineLink size={22}/></span>
								</CopyToClipboard>
							</StyledLink>
						</li>
						<li className="player__interaction-item">
							<a
								className="player__interaction-button"
								href={`https://vk.com/share.php?url=${link}`}
								target="_blank"
								rel="noreferrer"
							>
								<svg width="20" height="12" viewBox="0 0 20 12" fill="none">
									<path
										d="M19.8218 10.4724C19.768 10.3824 19.4355 9.65987 17.8354 8.17476C16.1602 6.61964 16.3852 6.87216 18.4029 4.18321C19.6317 2.54559 20.123 1.54552 19.9693 1.11798C19.823 0.710454 18.9192 0.817962 18.9192 0.817962L15.914 0.835464C15.914 0.835464 15.6914 0.805461 15.5252 0.904219C15.3639 1.00173 15.2602 1.22674 15.2602 1.22674C15.2602 1.22674 14.7839 2.49434 14.1488 3.57192C12.81 5.84584 12.2737 5.96585 12.0549 5.82458C11.5461 5.49581 11.6736 4.50199 11.6736 3.79693C11.6736 1.59302 12.0074 0.674202 11.0223 0.436684C10.6948 0.357928 10.4548 0.305424 9.61849 0.296673C8.54591 0.285423 7.6371 0.300424 7.12331 0.551692C6.78078 0.719205 6.51701 1.09298 6.67828 1.11423C6.87704 1.14049 7.32707 1.23549 7.56584 1.56052C7.87461 1.9793 7.86336 2.92187 7.86336 2.92187C7.86336 2.92187 8.04088 5.51581 7.44958 5.83834C7.0433 6.0596 6.48701 5.60832 5.29317 3.54442C4.68188 2.48809 4.21934 1.31925 4.21934 1.31925C4.21934 1.31925 4.13059 1.10173 3.97182 0.985475C3.77931 0.844214 3.50929 0.799211 3.50929 0.799211L0.651578 0.816712C0.651578 0.816712 0.222796 0.829213 0.0652839 1.01548C-0.0747265 1.18174 0.0540331 1.52426 0.0540331 1.52426C0.0540331 1.52426 2.2917 6.7584 4.82439 9.39735C7.14831 11.8163 9.78601 11.6575 9.78601 11.6575H10.9811C10.9811 11.6575 11.3424 11.6175 11.5261 11.4188C11.6961 11.2362 11.6899 10.8937 11.6899 10.8937C11.6899 10.8937 11.6661 9.28984 12.4112 9.05357C13.145 8.82106 14.0876 10.6037 15.0864 11.29C15.8415 11.8088 16.4153 11.695 16.4153 11.695L19.0867 11.6575C19.0867 11.6575 20.4843 11.5713 19.8218 10.4724Z"
										fill="#343434"
									/>
								</svg>
							</a>
						</li>
						<li className="player__interaction-item"><a
							className="player__interaction-button"
							href={`https://telegram.me/share/url?url=${link}`}
							target="_blank"
							rel="noreferrer"
						>
							<FaTelegramPlane size={22}/>
						</a>
						</li>
					</ul>
				</div>
			</section>
		</div>
	);
};

export default CollapseContent;
