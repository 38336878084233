import React from 'react';
import CatalogFavoriteListItem from './CatalogFavoriteListItem';
import FilterList from '../../components/FilterList';

const CatalogFavoriteList = ({ title, list, err}) => {
	return (

		<FilterList width={24}>
			<li className="catalog__filter-settings-item">
				<ul className="catalog__filter-settings-item-inner" style={{overflowY: 'auto'}}>
					<p className="text--small title--weight--medium pb-4">{title}</p>
					{!!list.length ? (
						<li>
							{
								list.filter((track) => track.src)
									.map((track) => <CatalogFavoriteListItem key={track.id} track={track}/>)
							}
						</li>
					) : (
						<p className="cart__list-header mb-3" style={{fontSize: 18}}>{err}</p>
					)}
	
				</ul>
			</li>
		</FilterList>
	);
};

export default CatalogFavoriteList;
