import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp} from 'react-icons/md';
import styled from 'styled-components';
import {useWindowSize} from 'react-use';
import CatalogAlbumDetailHeader from './CatalogAlbumDetailHeader';
import CatalogTracks from './CatalogTracks';
import BackBtn from '../../components/BackBtn';
import CatalogSort from './CatalogSort';

const StyledSection = styled.section`
position: relative;
`;

const StyledArrows = styled.div` 
    cursor: pointer;
    border-bottom: 1px solid #10DB92;
    width: 110px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
      position: absolute;
    top: -40px;
    right: 0;
`;

const CatalogAlbumDetail = ({onBack}) => {
	const {height: windowHeight} = useWindowSize();
	const {albumInfo} = useSelector((state) => state.catalog);
	const [open, setOpen] = useState(true);
	const height = windowHeight - (open ? 520 : 380);
	return (
		<>
			<div className="d-flex align-items-center justify-content-between">
				<div className={`${open ? 'mb-4' : ''}`}><BackBtn onClick={onBack}/></div>
				<div className="d-flex align-items-center justify-content-between">

					<StyledArrows onClick={() => setOpen((prev) => !prev)}>
						{
							open
								? <span>Свернуть</span>
								: <span>Развернуть</span>
						}
						{
							open
								? <MdOutlineKeyboardArrowUp size={24}/>
								: <MdOutlineKeyboardArrowDown size={24}/>
						}
					</StyledArrows>

					<CatalogSort/>
				</div>
			</div>
			<StyledSection className="card__detailed-content" height={windowHeight - 300}>

				<CatalogAlbumDetailHeader albumInfo={albumInfo} open={open}/>
				<CatalogTracks showHeader={false} height={height}/>
			</StyledSection>
		</>
	);
};

export default CatalogAlbumDetail;
