import React from 'react';
import {useCatalog} from './context/catalogContext';
import CatalogCollections from './CatalogCollections';
import CatalogAlbums from './CatalogAlbums';
import CatalogTracks from './CatalogTracks';
import CatalogPlaylists from './CatalogPlaylists';

const CatalogContent = () => {
	const {type} = useCatalog();
	return (
		<>
			{
				type === 'collections'
				&& <CatalogCollections/>
			}
			{
				type === 'albums'
				&& <CatalogAlbums />
			}
			{
				type === 'tracks'
				&& <CatalogTracks />
			}
			{
				type === 'playlists'
				&& <CatalogPlaylists />
			}
		</>
	);
};

export default CatalogContent;
