import React from 'react';
import styled from 'styled-components';
import {Circle} from 'rc-progress';

const StyledProgress = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;	
`;

const PlayerProgress = ({progress, children}) => {
	return (
		<StyledProgress progress={progress}>
			<Circle
				percent={progress}
				strokeWidth="7"
				trailWidth="7"
				strokeColor="#FFF"
				trailColor="#05BC7A"
				strokeLinecap="square"
			/>
			{children}
		</StyledProgress>
	);
};

export default PlayerProgress;
