import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PlayBtn from '../../components/PlayBtn';
import { usePlayer } from '../Player/context/playerContext';
import {getTrackPath} from '../../utils/track-utils';

const CatalogFavoriteListItem = ({ track }) => {
    const [isActive, setIsActive] = useState(false);
    const { currentTrack } = useSelector((state) => state.player);
    const {
        playing,
        togglePlayPause,
        loadTrack,
        setPlay,
        play,
    } = usePlayer();

    const toggleTrackHandler = useCallback(() => {
        if (track.src && !isActive) {
            loadTrack(track);
            setPlay(true);
        }
    }, [isActive, loadTrack, setPlay, track]);

    const trackDetailHandler = () => {
        const link = getTrackPath(`/catalog/?trackId=${track.code}&type=tracks`);
        window.location.assign(link);
    };

    useEffect(() => {
        setIsActive(currentTrack.id === track.id);
    }, [track, currentTrack]);

    return (
        <li>
            <div className="page__player page__player--minimal">
                <div className="row no-gutters">
                    <div className="col-2 center" onClick={toggleTrackHandler}>
                        <PlayBtn
                            play={playing && isActive && play}
                            onClick={togglePlayPause}
                            disabled={!track.src}
                        />
                    </div>
                    <div className="col-10">
                        <div className="text--small">
                            <p
                                className="page__player-song-name"
                                onClick={trackDetailHandler}
                                style={{cursor: 'pointer'}}
                            >
                                <b>
                                    {track.name}
                                </b>
                            </p>
                            <ul className="page__player-song-tags text--gray">
                                {
                                    !!track.tags && Object.values(track.tags).map((tag) => <li key={tag}>{tag}</li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default CatalogFavoriteListItem;
