import axios from 'axios';
// import {LOCAL_URL} from '../../config';

export default axios.create({
	baseURL: '/api/v1/',
	method: 'POST',
	headers: {
		'Cache-Control': 'no-cache',
		Pragma: 'no-cache',
		Expires: '0',
	},
});