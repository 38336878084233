import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Popconfirm} from 'antd';
import BasketTrackListItem from './BasketTrackListItem';
import {deleteAllTrackBasket} from '../../store/actions/basketActions';

const BasketTrackList = () => {
	const {tracks} = useSelector((state) => state.basket);
	const dispatch = useDispatch();
	const clearBasketHandler = () => {
		dispatch(deleteAllTrackBasket());
	};
	if (!tracks.length) {
		return null;
	}

	return (
		<div className="cart__list">
			<div className="cart__list-header">
				<div className="row justify-content-between align-items-center">
					<div className="col-auto">
						<p className="title--weight--medium text--middle">Треки ({tracks.length})</p>
					</div>
					<div className="col-auto">
						<Popconfirm
							title="Вы уверены, что хотите очистить корзину？"
							okText="Да"
							cancelText="Нет"
							onConfirm={clearBasketHandler}
							placement="topRight"
							okButtonProps={{
								className: 'button button--default button--success waves-light button--waves',
							}}
							cancelButtonProps={{
								className: 'button button--default button--bordered waves-light button--waves',
							}}
							className="confirm-popup"
						>
							<a
								className="d-inline-block text--gray text--small"
							>
								Очистить корзину
							</a>
						</Popconfirm>

					</div>
				</div>
			</div>
			<div className="cart__list-main text--small">
				<div className="cart__list-head">
					<div className="cart__list-row text--gray">
						<div className="cart__list-col cart__list-col--name">
							<p>Название</p>
						</div>
						<div className="cart__list-col cart__list-col--code">
							<p>Код</p>
						</div>
						<div className="cart__list-col cart__list-col--timing">
							<p>Хронометраж</p>
						</div>
						<div className="cart__list-col cart__list-col--price">
							<p>Цена</p>
						</div>
					</div>
				</div>
				<div className="cart__list-body text--small js-cart-list-output">

					<ul className="card__detailed-tracks js-cards-output">

						{
							!!tracks.length && tracks.map((track) => (
								<BasketTrackListItem
									key={track.id}
									track={track}
								/>
							))
						}

					</ul>

				</div>
			</div>
		</div>
	);
};

export default BasketTrackList;