import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {HiCheckCircle} from 'react-icons/hi';
import {StringParam, useQueryParam} from 'use-query-params';
import {notification} from 'antd';
import {BiErrorCircle} from 'react-icons/bi';
import {ERROR_COLOR, MAIN_COLOR} from '../../../config';
import {applyCouponBasket, deleteCouponBasket} from '../../store/actions/basketActions';
import {setBasketProperty} from '../../store/reducers/basketSlice';

const BasketTotal = () => {
	const dispatch = useDispatch();
	const [subscribeId] = useQueryParam('subscribeId', StringParam);
	const {tracks, activeLicense, coupon} = useSelector((state) => state.basket);
	const [totalPrice, setTotalPrice] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [couponName, setCouponName] = useState('');
	const [clearCoupon, setClearCoupon] = useState(false);
	const [error, setError] = useState(false);

	const couponHandler = (evt) => {
		setError(false);
		setClearCoupon(false);
		const {value} = evt.target;
		setCouponName(value);
	};

	const applyCoupon = useCallback(() => {
		if (subscribeId) {
			dispatch(setBasketProperty({property: 'couponName', value: couponName}));
		} else if (!!tracks.length) {
			dispatch(setBasketProperty({property: 'couponName', value: couponName}));
		}
	}, [couponName, dispatch, subscribeId, tracks]);

	const deleteCoupon = () => {
		dispatch(deleteCouponBasket());
		dispatch(setBasketProperty({property: 'coupon', value: null}));
		dispatch(setBasketProperty({property: 'couponName', value: ''}));
		setCouponName('');
		setError(false);
	};


	useEffect(() => {
		if (coupon) {
			setTotalPrice(coupon.base_price);
			setDiscount(coupon.discount);
			setClearCoupon(true);
			if (coupon.status) {
				notification.success({
					message: coupon.message,
					placement: 'bottomRight',
					description: 'Купон будет применен к заказу!',
				});
				setError(false);
			} else {
				notification.error({
					message: coupon.message,
					placement: 'bottomRight',
					description: 'Пожалуйста введите действующий купон!',
				});
				setError(true);
			}
		} else if (!!tracks.length) {
			const total = tracks.reduce((acc, track) => +track.price + acc, 0);
			setTotalPrice(total);
			setDiscount(0);
		} else {
			setTotalPrice(activeLicense.price);
			setDiscount(0);
		}
	}, [activeLicense, tracks, coupon]);

	return (
		<>
			{
				!!totalPrice && (
					<p className="text--extra-large title--weight--bold pb-4">Итого:&nbsp;
						<output>{new Intl.NumberFormat('ru-RU').format(totalPrice)} руб.</output>
					</p>
				)
			}

			<div className="d-flex flex-column align-items-end">
					<div
						className={`cart__coupon text--small d-inline-flex align-items-center ${error ? 'is-error' : 'is-valid'}`}
					>
						<label className="text--medium pr-2" htmlFor="cart-coupon-field" style={{color: '#000'}}>Купон:</label>
						<input
							className="cart__coupon-field"
							type="text"
							value={couponName}
							placeholder="Введите код с купона"
							id="cart-coupon-field"
							onChange={couponHandler}
							style={{width: '16rem'}}
						/>
						{coupon && !error && <HiCheckCircle color={MAIN_COLOR} size={22}/>}
						{error && <BiErrorCircle color={ERROR_COLOR} size={22}/>}
					</div>
				{
					couponName && (
						<div className="mt-3">
							{
								clearCoupon
									? (
										<button
											className="button button--default button--bordered waves-light button--waves"
											type="button"
											onClick={deleteCoupon}
										>
											<span className="button__content">Удалить купон</span>
										</button>
									)
									: (
										<button
											className="button button--default button--success waves-light button--waves"
											type="button"
											onClick={applyCoupon}
										>
											<span className="button__content">Применить купон</span>
										</button>
									)
							}
						</div>
					)
				}
			</div>

			<p className="text--middle title--weight--bold py-4">К оплате:&nbsp;
				<output>{new Intl.NumberFormat('ru-RU').format(totalPrice - discount)} руб.</output>
			</p>
		</>
	);
};

export default BasketTotal;
