import React, {
	useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import {Collapse} from 'react-collapse';
import IsVisible, {useIsVisible} from 'react-is-visible';
import {MdShoppingCart} from 'react-icons/md';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import Highlighter from 'react-highlight-words';
import {HiDotsVertical} from 'react-icons/hi';
import {Popover} from 'antd';
import {getImagePath} from '../../utils/image-utils';
import AudioPlayer from '../Player/AudioPlayer';
import {usePlayer} from '../Player/context/playerContext';
import DemoDownloadBtn from '../../components/DemoDownloadBtn';
import FavoriteBtn from '../../components/FavoriteBtn';
import CollapseContent from '../../components/CollapseContent';
import useBasketButton from './hooks/useBasketButton';
import {useFilter} from './context/filterContext';
import {setPopover} from '../../store/reducers/userListSlice';
import {resetPageNav} from '../../store/reducers/catalogSlice';
import {useCatalog} from './context/catalogContext';

const StyledLink = styled.div`
  cursor: pointer;

  mark {
    padding: 0;
    background: none;
    color: #10DB92;
  }
`;

const StyledDots = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  cursor: pointer;
`;
const CatalogTracksItem = ({track, num, hideInfo}) => {
	const nodeRef = useRef();
	const wrapperRef = useRef(null);
	const {setAlbum, setType, type} = useCatalog();
	const isVisible = useIsVisible(nodeRef);
	const dispatch = useDispatch();
	const {favorite, loadingTrackId, currentTrackPopover} = useSelector((state) => state.userList);
	const {isAuth} = useSelector((state) => state.user);
	const {tracks} = useSelector((state) => state.catalog);
	const {tracks: basketTracks} = useSelector((state) => state.basket);
	const [open, setOpen] = useState(false);
	const [isFavorite, setIsFavorite] = useState(false);
	const [downloadOpen, setDownloadOpen] = useState(false);
	const [popoverVisible, setPopoverVisible] = useState(false);
	const {favoriteHandler} = usePlayer();
	const {name, setChoices, choices, setSearches, searches} = useFilter();
	const {albumPoster, name: trackName, albumInfo} = track;
	const image = getImagePath(albumPoster);
	const [addTrackToBasket] = useBasketButton(isAuth, track);

	const detailHandler = useCallback(() => {
		setOpen((state) => !state);
		setPopoverVisible(false);
		setDownloadOpen(false);
		if (hideInfo) {
			hideInfo(true);
		}
	}, [hideInfo]);

	const setAlbumHandler = async () => {
		await setPopoverVisible(false);
		setAlbum(albumInfo.CODE);
		setType('albums');
		dispatch(resetPageNav());
		dispatch(setPopover(null));
		localStorage.setItem('prevTrack', true);
	};

	const downloadHandler = async () => {
		await setPopoverVisible(false);
		setOpen(true);
		setDownloadOpen(!!isAuth);
		dispatch(setPopover(null));
	};

	const popoverHandler = () => {
		dispatch(setPopover(track.id));
	};

	useEffect(() => {
		setPopoverVisible(currentTrackPopover === track.id);
	}, [currentTrackPopover, track]);

	useEffect(() => {
		if (favorite) {
			setIsFavorite(!!favorite.length && favorite.map((f) => f.id).includes(track.id));
		}
	}, [favorite, track]);

	useEffect(() => {
		if (tracks.length === 1) {
			setOpen(true);
		}
	}, [tracks]);

	// useEffect(() => {
	// 	if (!isVisible && open) {
	// 		setOpen(false);
	// 	}
	// }, [isVisible, open]);

	const inBasket = useMemo(() => {
		return basketTracks.find((item) => item.id === track.id);
	}, [basketTracks, track]);

	useEffect(() => {
		const mouseClickOutside = (evt) => {
			const {current: wrapper} = wrapperRef;
			// @ts-ignore
			if (wrapper && !wrapper.contains(evt.target)) {
				setPopoverVisible(false);
			}
		};
		document.addEventListener('mousedown', mouseClickOutside);
		return () => {
			document.removeEventListener('mousedown', mouseClickOutside);
		};
	}, []);

	useEffect(() => {
		setPopoverVisible(false);
	}, [type]);

	const getByTags = (tag) => {
		setChoices({
			...choices,
			genre: [tag],
		});
		setSearches([...searches.filter((search) => search.title !== tag), {
			title: tag,
			type: 'genre',
		}]);
	};

	return (
		<div ref={nodeRef} className={`mt-1 ${isVisible ? 'is-visible' : 'is=hidden'}`}>
			{
				!!track && (
					<div className="page__player page__player--extended js-player-on-page index-is-printed">

						<div className="js-page-player-detailed-parent">
							<div
								className="page__player-main d-flex align-items-center"
							>
								<div className="page__player-song d-flex align-items-center">
									<p className="js-page-player-track-index-output text--middle page__player-index flex-shrink-0">{num}</p>
									<div
										className="flex-shrink-0 js-page-player-detailed-trigger d-flex align-items-center"
									>
										<figure
											className="page__player-poster page__image"
											style={{background: '#DFDFDF'}}
										>
											{
												isVisible && (
													<img
														className="lazy__image lazy__image--static is-entered is-loaded"
														alt="Альтернативное описание"
														src={image}
														onClick={setAlbumHandler}
														style={{cursor: 'pointer'}}
													/>
												)
											}
										</figure>
										<StyledLink className="text--small">
											<p className="page__player-song-name" style={{maxWidth: '200px'}} onClick={detailHandler}>
												<Highlighter
													searchWords={[name]}
													autoEscape
													textToHighlight={trackName}
												/>
											</p>
											<ul className="page__player-song-tags text--gray">
												{
													!!track.tags && track.tags.genre.split(', ').map((tag) => (
														<li 
															onClick={() => getByTags(tag)}
															key={tag}
															className="hover"
														>{tag}
														</li>
													))
												}
											</ul>
										</StyledLink>
									</div>
								</div>
								<IsVisible once>
									{(loadWave) => <>{loadWave ? <AudioPlayer track={track} isVisible={isVisible}/>
										: <div className="page__player-duration"/>}</>}
								</IsVisible>

								<ul className="page__player-interaction d-flex align-items-center justify-content-between">
									<li className="player__interaction-item player__interaction-item--favorite">
										<FavoriteBtn
											isLoading={track.id === loadingTrackId}
											isFavorite={isFavorite}
											onClick={() => favoriteHandler(track, isFavorite)}
										/>
									</li>
									{/* { */}
									{/*	!!track.src && ( */}
									<li className="player__interaction-item player__interaction-item--demo">
										<DemoDownloadBtn track={track}/>
									</li>
									{/* ) */}
									{/* } */}
									<li
										className="player__interaction-item player__interaction-item--cart"
										onClick={addTrackToBasket}
									>
										<a
											className={`player__interaction-button waves-effect waves-button ${inBasket ? 'is-active' : ''}`}
										>
											<MdShoppingCart/>
										</a>
									</li>
									<li

										className="player__interaction-item player__interaction-item--menu js-dropdown"
									>
										<Popover
											visible={popoverVisible}
											placement="topRight"
											content={(
												<ul className="player__menu-list p-0" ref={wrapperRef}>
													<li><a className="d-inline-block" onClick={setAlbumHandler}>Перейти
														в альбом
													</a>
													</li>
													{isAuth && (
													<li><a className="d-inline-block" onClick={downloadHandler}>Скачать
														по подписке
													</a>
													</li>
													)}
												</ul>
											)}
											trigger="click"
										>
											<StyledDots onClick={popoverHandler}>
												<HiDotsVertical color="#10DB92"/>
											</StyledDots>
										</Popover>
									</li>
								</ul>
							</div>
							{
								isVisible && (
									<Collapse isOpened={open}>
										<CollapseContent
											track={track}
											addTrackToBasket={addTrackToBasket}
											downloadOpen={downloadOpen}
										/>
									</Collapse>
								)
							}
						</div>
					</div>
				)
			}
		</div>
	);
};

export default CatalogTracksItem;
