import React from 'react';
import styled from 'styled-components';
import {scrollBar} from '../styles/sc-mixins';

const StyledList = styled.ul`
  opacity: 1;
  overflow-y: auto;
  width: ${(props) => props.width || '14.0625'}rem;
  box-shadow: 4px 0 15px rgba(198, 198, 198, 0.6);

  > li {
    padding: 1.5625rem 1.15625rem;
  }

  ul {
    ${scrollBar()}
  }
`;

const FilterList = ({children, width, ...props}) => {
	return (
		<StyledList className="catalog__filter-settings" {...props} width={width}>
			{children}
		</StyledList>
	);
};

export default FilterList;
