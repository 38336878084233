import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useWindowSize} from 'react-use';
import {useCatalog} from './context/catalogContext';
import {useFilter} from './context/filterContext';
import {getAlbumInfo} from '../../store/actions/catalogActions';
import CatalogCollectionList from './CatalogCollectionList';
import BackBtn from '../../components/BackBtn';
import CatalogAlbumDetail from './CatalogAlbumDetail';
import CatalogAlbumList from './CatalogAlbumList';

const CatalogCollections = () => {
	const ref = useRef();
	const dispatch = useDispatch();
	const {height} = useWindowSize();
	const {collections, albums, pageNav} = useSelector((state) => state.catalog);
	const {
		collectionId, albumId, setCollection, setAlbum, getCurrentPageData,
	} = useCatalog();
	const {getFilters} = useFilter();
	const [depth, setDepth] = useState(1);

	const filters = useMemo(() => {
		return {...getFilters()};
	}, [getFilters]);

	const getData = useCallback(() => {
		getCurrentPageData({
			filters,
			infinity: true,
			page: pageNav.curPage + 1,
		});
	}, [getCurrentPageData, filters, pageNav]);

	const backToCollections = () => {
		/* кнопка назад, для списка альбомов,
		 удаляет параметр id коллекции,
		 тем самым возвращвет в список коллекций.
		 */
		setCollection(undefined);
	};

	const backToCollection = () => {
		/* кнопка назад, для альбома,
		 удаляет параметр id альбома,
		 тем самым возвращвет в список альбомов.
		 */
		setAlbum(undefined);
	};

	useEffect(() => {
		// определяем уровень вложенности,
		// 1 - коллекции,
		// 2 - альбомы коллекции
		// 3 - альбом
		switch (true) {
			case (!!collectionId && !albumId):
				setDepth(2);
				break;
			case (!!collectionId && !!albumId):
				setDepth(3);
				break;
			default:
				setDepth(1);
		}
	}, [albumId, collectionId]);

	useEffect(() => {
		if (depth === 3) {
			dispatch(getAlbumInfo({id: albumId}));
		}
	}, [albumId, depth, dispatch]);

	return (
		<>
			{
				depth === 3 && <CatalogAlbumDetail onBack={backToCollection}/>
			}

			{
				depth === 2 && (
					<>
						<div className="catalog__content--albums">
							<BackBtn onClick={backToCollections}/>
							<div
								id="scrollableDiv"
								className="Catalog__content-scroll-container mt-4"
								style={{
									height: height - 380,
									// width: 1530,
									overflow: 'auto',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<InfiniteScroll
									ref={ref}
									dataLength={albums.length}
									next={getData}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									hasMore={pageNav.curPage !== pageNav.allPage}
									loader={<h3>Загрузка ...</h3>}
									scrollableTarget="scrollableDiv"
								>
									<CatalogAlbumList/>
								</InfiniteScroll>
							</div>
						</div>
					</>
				)
			}

			{
				depth === 1 && <CatalogCollectionList collections={collections}/>
			}
		</>

	);
};

export default CatalogCollections;
