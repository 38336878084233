import React from 'react';
import styled from 'styled-components';
import {getImagePath} from '../../utils/image-utils';
import {useCatalog} from './context/catalogContext';

const StyledLink = styled.div`
  cursor: pointer;
`;

const CatalogPlaylistListItem = ({playlist}) => {
	const {setPlaylist} = useCatalog();
	const {
		id, name, poster, description,
	} = playlist;
	const image = getImagePath(poster);

	const setPlaylistHandler = () => {
		setPlaylist(id);
	};

	return (
		<li>
			<StyledLink onClick={setPlaylistHandler}>
				<section className="card__playlist h-100">
					<div className="card__playlist-image">
						<div className="page__image">
							<img
								className="lazy__image lazy__image--static is-entered is-loaded"
								alt={description}
								src={image}
							/>
						</div>
					</div>
					<div className="card__playlist-content">
						<h3 className="title title--weight--bold">{name}</h3>
					</div>
				</section>
			</StyledLink>
		</li>
	);
};

export default CatalogPlaylistListItem;
