import {createSlice} from '@reduxjs/toolkit';
import {getFilterData} from '../actions/filterActions';

const setError = (state, action) => {
	state.status = 'rejected';
	state.error = action.payload;
};

const filterSlice = createSlice({
	name: 'filters',
	initialState: {
		variants: null,
		settings: [
			{
				id: 'genre',
				title: 'Стиль',
				type: 'checkbox',
			},
			{
				id: 'moods',
				title: 'Настроение',
				type: 'checkbox',
			},
			{
				id: 'tempo',
				title: 'Темп',
				type: 'checkbox',
				description: 'Установите темп',
			},
			{
				id: 'key',
				title: 'Тональность',
				type: 'checkbox',
			},
			{
				id: 'has_vocals',
				title: 'Вокал',
				type: 'radio',
				defaultValue: 'Любой',
				// choices: [
				// 	'Да',
				// 	'Нет',
				// ]
			},
			{
				id: 'length',
				title: 'Хронометраж',
				type: 'length',
			},
			{
				id: 'bpm',
				title: 'BPM',
				type: 'range',
				description: 'Задайте ритм',
			},
		],
		status: null,
		error: null,
	},
	reducers: {},
	extraReducers: {
		[getFilterData.pending]: (state) => {
			state.status = 'loading';
			state.error = null;
		},
		[getFilterData.fulfilled]: (state, action) => {
			state.status = 'success';
			state.variants = action.payload;
		},
		[getFilterData.rejected]: setError,
	},
});

export default filterSlice.reducer;
