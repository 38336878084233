import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {PLAYER_ID} from '../../../config';

const playerRoot = document.getElementById(PLAYER_ID);

class PlayerPortal extends Component {
	render() {
		const {children} = this.props;
		if (!playerRoot) {
			return null;
		}
		return ReactDOM.createPortal(
			children,
			playerRoot,
		);
	}
}

export default PlayerPortal;
