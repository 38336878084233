import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {useWindowSize} from 'react-use';
import CatalogAlbumDetailHeader from './CatalogAlbumDetailHeader';
import {scrollBar} from '../../styles/sc-mixins';
import Loader from '../../components/Loader';
import CatalogTracks from './CatalogTracks';

const StyledSection = styled.section`
   ${scrollBar()};
`;

const CatalogPlaylistDetail = () => {
	const {height: windowHeight} = useWindowSize();
	const {playlistInfo, tracks, isLoading} = useSelector((state) => state.catalog);
	return (
		<StyledSection className="card__detailed-content">
			{isLoading
				? <Loader/>
				: (
					<>
						<CatalogAlbumDetailHeader albumInfo={playlistInfo} hideSort/>
						<CatalogTracks showHeader={false} height={windowHeight - 400}/>
					</>
				)
			}
		</StyledSection>
	);
};

export default CatalogPlaylistDetail;
