import React from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';

const BasketLicenseListItem = ({ onClick, license, isActive }) => {
	const {
		id, type_license, scope,
	} = license;
	const moreLink = (type) => {
		if (type === 'Стандартная') {
			return '/prices_and_types_licenses/?code=standard';
		}
		if (type === 'Расширенная') {
			return '/prices_and_types_licenses/?code=extended';
		}
		if (type === 'Максимальная') {
			return '/prices_and_types_licenses/?code=maximum';
		}
	};

	const link = moreLink(type_license);

	return (
		<div
			className="col-4"
			onClick={() => onClick(id)}
		>
			<section
				className={`cart__license d-flex flex-column ${isActive ? 'is-active' : ''}`}
				data-toggle-active="cart-licenses"
			>
				<header className="cart__license-header">
					<div className="row">
						<div className="col-7">
							<h3 className="title--weight--bold text--extra-large">{type_license}</h3>
						</div>
						{/* <div className="col-5 text-right text--success"> */}
						{/*	{ */}
						{/*		!!totalPrice */}
						{/*		&& <p className="title--weight--bold text--extra-large">{new Intl.NumberFormat('ru-RU').format(totalPrice)} ₽</p> */}
						{/*	} */}
						{/* </div> */}
					</div>
				</header>
				<ul className="list text--small text--gray-brown mb-4 pb-2 list--checked">
					{
						scope.map((line, idx) => <li key={idx}>{line}</li>)
					}
				</ul>
				<a className="text--underlined text--small mt-auto" target="_blank" href={link} rel="noreferrer">Подробнее</a>
				<span
					className="cart__license-check"
				>
									       <IoIosCheckmarkCircle size={30} color="#10DB92"/>
    </span>
			</section>
		</div>
	);
};

export default BasketLicenseListItem;
