import React from 'react';
import {useSelector} from 'react-redux';
import CatalogNavItem from './CatalogNavItem';

const CatalogNav = () => {
	const {nav} = useSelector((state) => state.catalog);

	return (
		<nav className="catalog__nav mt-5">
			{
				nav.map((item) => <CatalogNavItem key={item.id} navItem={item}/>)
			}
		</nav>
	);
};

export default CatalogNav;
