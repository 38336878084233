import React from 'react';
import styled from 'styled-components';
import {ClipLoader} from 'react-spinners';

const StyledLoader = styled.div`
  display: flex;
  margin: 150px 40%;
`;

const Loader = () => {
	return (
		<StyledLoader>
			<ClipLoader color="#10DB92" size={50}/>
		</StyledLoader>
	);
};

export default Loader;