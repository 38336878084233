import React, {useCallback, useRef} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useCatalog} from './context/catalogContext';
import {getImagePath} from '../../utils/image-utils';
import {resetPageNav, setLastAlbumOffset} from '../../store/reducers/catalogSlice';

const StyledLink = styled.div`
  cursor: pointer;
  height: 100%;
`;

const CatalogAlbumsCard = ({album, rowIndex}) => {
	const ref = useRef();
	const dispatch = useDispatch();
	const {setAlbum} = useCatalog();
	const {
		id, code, name, poster, tracksQuantity, releaseDate,
	} = album;
	const image = getImagePath(poster);

	const setAlbumHandler = useCallback(() => {
		if (code) {
			setAlbum(code || id);
			dispatch(resetPageNav());
			if (ref.current && rowIndex > 2) {
				const {height} = ref.current.getBoundingClientRect();
				const top = (height + 20) * rowIndex - (height + height / 2);
				dispatch(setLastAlbumOffset(top));
			}
		}
	}, [code, dispatch, id, rowIndex, setAlbum]);

	return (
		<StyledLink
			ref={ref}
			id={code.toString() || ''}
			key={id}
			onClick={setAlbumHandler}
		>
			<section className="card__album">
				<div className="card__album-image">
					<div className="page__image">
						<img
							className="lazy__image lazy__image--static is-entered is-loaded"
							alt="Альтернативное описание"
							src={image}
						/>
					</div>
				</div>
				<div className="card__album-content d-flex flex-column flex-grow-1">
					<h3 className="title title--weight--medium text--small mb-3">
						{name}
					</h3>
					<p className="text--extra-small d-flex flex-column mt-auto">
							<span>
								<span className="text--gray">Треков:&nbsp;</span>
                              <output>{tracksQuantity}</output>
							</span>
						<span className="pt-1">
								<span className="text--gray">Релиз:&nbsp;</span>
                              <output>{releaseDate}</output>
							</span>
					</p>
				</div>
			</section>
		</StyledLink>
	);
};

export default CatalogAlbumsCard;
