import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import {AudioPlayerProvider} from 'react-use-audio-player';
import {QueryParamProvider} from 'use-query-params';
import store from './store';

import Basket from './app/Basket/Basket';
import {Catalog} from './app/catalog';
import {
	CATALOG_ID, BASKET_ID, PROFILES_ID, HISTORY_ID, FAVORITE_ID, SUBSCRIBES_ID,
} from '../config';

// import 'antd/dist/antd.css';
import './styles.scss';
import 'rc-slider/assets/index.css';
import Profiles from './app/Profiles/Profiles';
import History from './app/History/History';
import Favorite from './app/Favorite/Favorite';
import Subscribes from './app/Subscribes/Subscribes';
import CatalogContextProvider from './app/catalog/context/catalogContext';

document.addEventListener('DOMContentLoaded', () => {
	const app = document.getElementById('app');
	if (app) {
		app.setAttribute('id', BASKET_ID);
	}
	const reactCatalog = document.getElementById(CATALOG_ID);
	const reactBasket = document.getElementById(BASKET_ID);
	const reactProfiles = document.getElementById(PROFILES_ID);
	const reactHistory = document.getElementById(HISTORY_ID);
	const reactFavorite = document.getElementById(FAVORITE_ID);
	const reactSubscribes = document.getElementById(SUBSCRIBES_ID);

	if (reactCatalog) {
		ReactDOM.render(
			<Provider store={store}>
				<Router>
					<QueryParamProvider ReactRouterRoute={Route}>
						<AudioPlayerProvider>
							<Catalog/>
						</AudioPlayerProvider>
					</QueryParamProvider>
				</Router>
			</Provider>,
			reactCatalog,
		);
	}

	if (reactBasket) {
		ReactDOM.render(
			<Provider store={store}>
				<Router>
					<QueryParamProvider ReactRouterRoute={Route}>
						<AudioPlayerProvider>
							<CatalogContextProvider>
								<Basket/>
							</CatalogContextProvider>
						</AudioPlayerProvider>
					</QueryParamProvider>
				</Router>
			</Provider>,
			reactBasket,
		);
	}

	if (reactProfiles) {
		ReactDOM.render(
			<Provider store={store}>
				<Router>
					<QueryParamProvider ReactRouterRoute={Route}>
						<Profiles/>
					</QueryParamProvider>
				</Router>
			</Provider>,
			reactProfiles,
		);
	}

	if (reactHistory) {
		ReactDOM.render(
			<Provider store={store}>
				<Router>
					<QueryParamProvider ReactRouterRoute={Route}>
						<AudioPlayerProvider>
							<History/>
						</AudioPlayerProvider>
					</QueryParamProvider>
				</Router>
			</Provider>,
			reactHistory,
		);
	}

	if (reactFavorite) {
		ReactDOM.render(
			<Provider store={store}>
				<Router>
					<QueryParamProvider ReactRouterRoute={Route}>
						<AudioPlayerProvider>
							<Favorite/>
						</AudioPlayerProvider>
					</QueryParamProvider>
				</Router>
			</Provider>,
			reactFavorite,
		);
	}

	if (reactSubscribes) {
		ReactDOM.render(
			<Provider store={store}>
				<Router>
					<QueryParamProvider ReactRouterRoute={Route}>
						<AudioPlayerProvider>
							<Subscribes/>
						</AudioPlayerProvider>
					</QueryParamProvider>
				</Router>
			</Provider>,
			reactSubscribes,
		);
	}
});
