import React from 'react';
import styled, {css} from 'styled-components';
import {Input} from 'antd';

const StyledInput = styled(Input)`
	${({error}) => error && css`
        box-shadow: 0 0 3px 1px #ff6567 inset;
	`}
`;

const ProfilesForm = ({profile, inputHandler, changes}) => {
	return (
		<>
			{
				profile && (
					<table className="personal__profiles-data text--small">
						{
							profile.props && profile.props.map((prop) => {
								if (prop.code === 'STAMP' || prop.code === 'SIGNATURE') {
									return null;
								}
								const isRequired = prop.required === 'Y';
								const value = typeof changes[prop.id] !== 'undefined'
									? changes[prop.id]
									: prop.value;
								return (
									<tr key={prop.id} className="personal__profiles-data-tr">
										<th className="personal__profiles-data-th">{prop.name}{isRequired ? '*' : ''}</th>
										<td className="personal__profiles-data-td">
											<StyledInput
												error={isRequired && !value}
												className="personal__profiles-data-field"
												type="text"
												onChange={inputHandler(prop.id, prop.code)}
												value={value}
											/>
										</td>
									</tr>
								);
							})
						}
					</table>
				)
			}
			<div className="mt-3">
				* - поля, обязательные для заполнения
			</div>
		</>
	);
};

export default ProfilesForm;
