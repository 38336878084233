import React from 'react';
import AudioPlayerBottom from './AudioPlayerBottom';
import PlayerPortal from './PlayerPortal';

const Player = () => {
	return (
		<PlayerPortal>
			<AudioPlayerBottom/>
		</PlayerPortal>
	);
};

export default Player;
