export const CATALOG_ID = 'react-catalog';
export const PLAYER_ID = 'react-player';
export const SEARCH_ID = 'react-search';
export const BASKET_ID = 'react-basket';
export const PROFILES_ID = 'react-profiles';
export const HISTORY_ID = 'react-history';
export const FAVORITE_ID = 'react-favorite';
export const SUBSCRIBES_ID = 'react-subscribes';

export const DOMEN = 'https://zvukobaza.itfactory.site';
export const LOCAL_DOMEN = 'http://zvukobaza.bx';
export const BASE_URL = `${DOMEN}/api/v1/`;
export const LOCAL_URL = `${LOCAL_DOMEN}/api/v1/`;

export const BASKET_TRACK_PRICE = 2500;
export const MAIN_COLOR = '#10DB92';
export const ERROR_COLOR = '#E24C4B';

export const MAX_CHOICES = 6;
