import {createAsyncThunk} from '@reduxjs/toolkit';
import qs from 'qs';
import post from '../../rest/postLocal';
import get from '../../rest/getLocal';

export const getTracksBasket = createAsyncThunk(
	'basket/tracks/get',
	async (_, {rejectWithValue, getState}) => {
		try {
			const {activeLicense} = getState().basket;

			const params = {
				code_license: activeLicense.code,
			};
			const {data} = await get({
				url: 'basket/tracks/get',
				params,
			});
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const addTrackBasket = createAsyncThunk(
	'basket/tracks/add',
	async ({code_track}, {rejectWithValue, dispatch}) => {
		try {
			const params = {
				code_track,
			};
			const {data} = await post({
				url: 'basket/tracks/add',
				data: qs.stringify(params),
			});
			dispatch(getTracksBasket());
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const deleteTrackBasket = createAsyncThunk(
	'basket/tracks/delete',
	async ({code_track}, {rejectWithValue, dispatch}) => {
		try {
			const params = {
				code_track,
			};
			const {data} = await post({
				url: 'basket/tracks/delete',
				data: qs.stringify(params),
			});
			dispatch(getTracksBasket());
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const deleteAllTrackBasket = createAsyncThunk(
	'basket/tracks/deleteall',
	async (_, {rejectWithValue, dispatch}) => {
		try {
			const {data} = await post({
				url: 'basket/tracks/deleteall',
			});
			dispatch(getTracksBasket());
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getBasketTracksPrice = createAsyncThunk(
	'basket/tracks/price',
	async (_, {rejectWithValue}) => {
		try {
			const {data} = await get({
				url: 'basket/tracks/price',
			});
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getBasketSubscribesPrice = createAsyncThunk(
	'basket/subscribe/price',
	async ({subscribeId}, {rejectWithValue}) => {
		try {
			const {data} = await get({
				url: 'basket/subscribe/price',
				params: {
					id: subscribeId,
				},
			});
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const applyCouponBasket = createAsyncThunk(
	'basket/applyCouponBasket',
	async ({coupon_name}, {rejectWithValue, dispatch}) => {
		try {
			const {data} = await post({
				url: 'basket/subscribe/apply_coupon',
				data: qs.stringify({coupon_name}),
			});
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const deleteCouponBasket = createAsyncThunk(
	'basket/deleteCouponBasket',
	async (_, {rejectWithValue, dispatch}) => {
		try {
			const {data} = await post({
				url: 'basket/subscribe/delete_coupon',
			});
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const deleteSubscribeBasket = createAsyncThunk(
	'basket/deleteSubscribeBasket',
	async ({basket_id}, {rejectWithValue, dispatch}) => {
		try {
			const {data} = await post({
				url: 'basket/subscribe/delete',
				data: qs.stringify({basket_id}),
			});
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const deleteAllSubscribeBasket = createAsyncThunk(
	'/basket/subscribe/deleteall',
	async (_, {rejectWithValue, dispatch}) => {
		try {
			const {data} = await post({
				url: '/basket/subscribe/deleteall',
			});
			dispatch(getTracksBasket());
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const createOrderTracks = createAsyncThunk(
	'basket/createOrderTracks',
	async (_, {rejectWithValue, getState, dispatch}) => {
		try {
			const {activeLicense} = getState().basket;
			const {currentProfileId} = getState().user;
			const {data} = await post({
				url: 'basket/tracks/create_order',
				data: qs.stringify({
					id_profile: currentProfileId,
					id_license: activeLicense.id,
				}),
			});
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const createOrderSubscribe = createAsyncThunk(
	'basket/createOrderSubscribe',
	async (_, {rejectWithValue, getState, dispatch}) => {
		try {
			const {activeLicense} = getState().basket;
			const {currentProfileId} = getState().user;
			const {data} = await post({
				url: 'basket/subscribe/create_order',
				data: qs.stringify({
					id_profile: currentProfileId,
					id_license: activeLicense.id,
				}),
			});
			BX.onCustomEvent('updateBasket');
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);
