import React, {useRef} from 'react';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {setUserProperty} from '../../../store/reducers/userSlice';

const StyledUploader = styled.div`
	width: 200px;
	input{
		width: 100%;
		height: 100%;
		opacity: 0;
		display: none;
	}
`;

const ProfileFileUploader = ({title = 'Загрузить картинку', fileType}) => {
	const inputEl = useRef();
	const dispatch = useDispatch();
	const buttonClickHandler = () => {
		if (inputEl && inputEl.current) {
			inputEl.current.click();
		}
	};
	const inputHandler = (evt) => {
		const {files} = evt.target;
		if (files) {
			const file = files[0];
			dispatch(setUserProperty({ property: fileType, value: file }));
		}
	};
	return (
		<StyledUploader>
			<button
				onClick={buttonClickHandler}
				className="button button--default button--bordered waves-light button--waves"
				type="button"
			><span className="button__content">{title}</span>
			</button>
			<input
				ref={inputEl}
				onChange={inputHandler}
				type="file"
				accept=".png,.jpg,.jpeg"
			/>
		</StyledUploader>
	);
};

export default ProfileFileUploader;
