export const getTime = (seconds) => {
	const minutes = seconds > 59
		? Math.floor(seconds / 60)
		: 0;
	const remainingSeconds = seconds - (60 * minutes);
	return [minutes, remainingSeconds];
};

export const getTimeString = (seconds) => {
	const arrTime = getTime(seconds);
	const min = +arrTime[0] >= 10 ? arrTime[0] : `0${arrTime[0]}`;
	const sec = +arrTime[1] >= 10 ? arrTime[1] : `0${arrTime[1]}`;
	return `${min}:${sec}`;
};

export const getSeconds = (time) => {
	const times = time.split(':');
	return +(times[0] * 60) + +times[1];
};
