import React, {
	memo, useCallback, useEffect, useRef, useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import {BsPauseFill, BsPlayFill} from 'react-icons/bs';
import {
	MdFavorite, MdShoppingCart, MdSkipNext, MdSkipPrevious,
} from 'react-icons/md';
import {BiDownload} from 'react-icons/bi';
import {HiDotsVertical} from 'react-icons/hi';
import styled from 'styled-components';
import ReactWaves from '@dschoon/react-waves';
import {usePlayer} from './context/playerContext';
import {getTimeString} from '../../utils/date-utils';
import PlayerProgress from './components/PlayerProgress';
import PlayerVolume from './components/PlayerVolume';
import useBasketButton from '../catalog/hooks/useBasketButton';
import {resetPageNav} from '../../store/reducers/catalogSlice';
import {useCatalog} from '../catalog/context/catalogContext';
import { addTrackBasket } from '../../store/actions/basketActions';
import { addFavorite } from '../../store/actions/userListActions';
import { getTrackPath } from '../../utils/track-utils';

const StyledControl = styled.button`
  max-width: 24px;
  padding: 0;
`;

const AudioPlayerBottom = () => {
	const dispatch = useDispatch();
	const player = useRef();
	const {isAuth} = useSelector((state) => state.user);
	const {tracks} = useSelector((state) => state.basket);
	const [isFavorite, setIsFavorite] = useState(false);
	const {currentTrack, playerList} = useSelector((state) => state.player);
	const {favorite, loadingTrackId, isLoading} = useSelector((state) => state.userList);
	const [volume, setVolume] = useState(1);
	const [isBasket, setIsBasket] = useState(false);
	const {setAlbum, setType} = useCatalog();

	const {
		loading,
		setLoading,
		progress,
		progress2,
		setProgress,
		setProgress2,
		position,
		setPosition,
		playing,
		setPlaying,
		favoriteHandler,
		nextTrack,
		prevTrack,
		src,
		loadTrack,
		play,
		togglePlayPause,
		arrows,
		setArrows,
	} = usePlayer();

	const [addTrackToBasket] = useBasketButton(isAuth, currentTrack);
	const link = getTrackPath(`/catalog/?trackId=${currentTrack.code}&type=tracks`);

	const onPosChange = useCallback((pos, wavesurfer) => {
		const percent = (pos / wavesurfer.getDuration()) * 100;
		setProgress(percent);
		setPosition(pos);
		localStorage.setItem('progress', percent.toString());
		localStorage.setItem('position', pos.toString());
	}, [setPosition, setProgress]);

	const volumeHandler = (value) => {
		localStorage.setItem('volume', value.toString());
		setVolume(value);
	};

	const onReady = useCallback(({wavesurfer}) => {
		setLoading(false);
		setPlaying(true);
		wavesurfer.play();
		player.current = wavesurfer;
		const volume = localStorage.getItem('volume') || 1;
		setVolume(volume);
	}, [setLoading, setPlaying]);

	const nextTrackHandler = useCallback(() => {
		if (player.current) {
			try {
				player.current.pause();
			} catch (error) {
				player.current.backend.setState('paused');
			}
			nextTrack();
		}
	}, [nextTrack]);

	const prevTrackTrackHandler = useCallback(() => {
		const position = localStorage.getItem('position');
		if(position > 4 && player.current) {
			player.current.seekTo(0);
		} else {
			prevTrack();
		}
	}, [prevTrack]);

	const setAlbumHandler = () => {
		const path = window.location.pathname;
		const isCatalog = path.includes('catalog');
		const codeAlbum = currentTrack?.albumInfo?.CODE || currentTrack.code.slice(0, -4);
		if (codeAlbum) {
			setAlbum(codeAlbum);
			setType('albums');
			dispatch(resetPageNav());
			if (!isCatalog) {
				const url = window.location.origin;
				const newPath = `/catalog/?album=${codeAlbum}&type=albums`;
				window.location.href = url + newPath;
			}
		}
	};

	useEffect(() => {
		if (player.current && player.current.isReady) {
			setLoading(false);
		}
	}, [player, setLoading]);

	useEffect(() => {
		if (src) {
			setLoading(true);
		}
	}, [setLoading, src]);

	useEffect(() => {
		if (player.current) {
			const time = progress2 / 100;
			player.current.seekTo(time);
		}
	}, [progress2]);

	useEffect(() => {
		if (!currentTrack.id) {
			const track = localStorage.getItem('track');
			const progress = localStorage.getItem('progress');
			const position = localStorage.getItem('position');
			if (track) {
				loadTrack(JSON.parse(track));
				setProgress(+progress);
				setProgress2(+progress);
				setPosition(+position);
				setVolume(0);
			}
		} else if (!!isAuth) {
			localStorage.setItem('track', JSON.stringify(currentTrack));
		} else {
			localStorage.removeItem('track');
		}
	}, [currentTrack, isAuth, loadTrack, setPosition, setProgress, setProgress2]);

	useEffect(() => {
		const preAuthTrack = localStorage.getItem('authTrack');
		const preAuthFavoriteTrack = localStorage.getItem('authFavoriteTrack');
		if (isAuth && (preAuthTrack || preAuthFavoriteTrack)) {
			if (preAuthTrack) {
				const track = JSON.parse(preAuthTrack);
				dispatch(addTrackBasket({ code_track: track.code }));
				localStorage.removeItem('authTrack');
			}
			if (preAuthFavoriteTrack) {
				const track = JSON.parse(preAuthFavoriteTrack);
				dispatch(addFavorite({trackId: track.id}));
				localStorage.removeItem('authFavoriteTrack');
			}
		}
	}, [isAuth]);

	useEffect(() => {
		if (favorite && !!favorite.length) {
			setIsFavorite(!!favorite.map((f) => f.id).includes(currentTrack.id));
		}
	}, [favorite, currentTrack]);

	useEffect(() => {
		if (tracks && !!tracks.length) {
			setIsBasket(!!tracks.find((f) => f.id === currentTrack.id));
		}
	}, [tracks, currentTrack]);

	useEffect(() => {
		const trackIndex = playerList.findIndex((track) => track.id === currentTrack.id);
		// curArrows.next = playerList[trackIndex + 1] && playerList[trackIndex + 1].src;
		const prev = playerList[trackIndex - 1] && playerList[trackIndex - 1].src;
		setArrows({
			prev,
			next: !!playerList.length,
		});
	}, [playerList, currentTrack, setArrows]);

	useEffect(() => {
		if (progress >= 100) {
			nextTrack();
		}
	}, [nextTrack, progress]);

	const openTrack = (link) => {
		window.open(link, '_blank');
	};

	return (
		<div className="player">
			<div className="container container--large">
				<div className="player__wrapper d-flex">
					<div className="player__controls d-flex">
						<StyledControl
							onClick={prevTrackTrackHandler}
							className="player__control player__control-track js-page-player-track"
							type="button"
							data-direction="prev"
						>
							<MdSkipPrevious size={24} color={src && arrows.prev ? '#fff' : '#0B9A66'}/>
						</StyledControl>
						<div className="player__control player__control-play">
							<PlayerProgress progress={src ? progress : 0}>
								<button
									onClick={(
										!!src && (togglePlayPause)
									)}
									className={`player__control-play-button js-page-player-play${playing && play ? '' : ' svg-margin'}`}
									type="button"
									style={{
										background: src ? '#fff' : '#0B9A66',
									}}
								>
									{
										loading
											? (
												<div style={{padding: '4px 0 0 0'}}>
													<ClipLoader color="#10DB92" size={26}/>
												</div>
											)

											: (
												<>

													{
														playing && play
															? <BsPauseFill size={24}/>
															: <BsPlayFill size={24}/>
													}

												</>
											)
									}
								</button>
							</PlayerProgress>

						</div>
						<StyledControl
							onClick={nextTrackHandler}
							className="player__control player__control-track js-page-player-track"
							type="button"
							data-direction="next"
						>
							<MdSkipNext size={24} color={src && arrows.next ? '#fff' : '#0B9A66'}/>
						</StyledControl>
					</div>
					<div className="player__song" style={{cursor: "pointer"}} onClick={() => openTrack(link)}>
						<p className="player__song-name pb-1">
							<output className="js-page-player-song-name">
								{
									currentTrack.name
										? <span>{currentTrack.name}</span>
										: <span>Трек не выбран</span>
								}

							</output>
						</p>
						{
							!!currentTrack.tags
							&& (
								<ul
									className="player__song-tags"
									style={{opacity: 1, visibility: 'visible'}}
								>
									{
										Object.values(currentTrack.tags).map((tag) => <li key={tag}>{tag}</li>)
									}
								</ul>
							)
						}
					</div>
					<div className="player__duration d-flex align-items-center justify-content-between">
						<p
							className="player__duration-current js-page-player-song-duration-current text--small"
							style={{color: src ? '#fff' : '#0B9A66', display: "contents"}}
						>
							{!!position && getTimeString(position.toFixed())}
						</p>

						<div className="player__oscillogram ">
							{
								!!src && (
									<ReactWaves
										audioFile={currentTrack.src}
										className="react-waves"
										options={{
											barHeight: 2,
											barWidth: 6,
											barGap: 1,
											cursorWidth: 0,
											height: 45,
											hideScrollbar: true,
											progressColor: '#fff',
											responsive: true,
											waveColor: '#0B9A66',
											removeMediaElementOnDestroy: true,
										}}
										volume={volume}
										zoom={1}
										playing={playing && play}
										onPosChange={onPosChange}
										onReady={onReady}
										pos={position}
									/>
								)
							}

						</div>
						<p
							className="player__duration-total js-page-player-song-duration-total text--small"
							style={{color: src ? '#fff' : '#0B9A66', display: "contents"}}
						>{src && getTimeString(currentTrack.length)}
						</p>
					</div>
					<ul className="player__interaction d-flex align-items-center justify-content-between">
						<li className="player__interaction-item player__interaction-item--volume js-dropdown">
							{
								src && <PlayerVolume isActive={!!src} setVolume={volumeHandler} volume={volume}/>
							}
						</li>
						<li className="player__interaction-item player__interaction-item--favorite">

							{
								src
									? (
										<a
											className={`player__interaction-button waves-effect waves-button ${isFavorite ? 'is-active' : ''}`}
											href="#"
											onClick={!!src ? () => favoriteHandler(currentTrack, isFavorite) : () => {
											}}
										>
											{
												!isLoading
													? <MdFavorite size={24}/>
													: (
														<div style={{padding: '4px 0 0 0'}}>
															<ClipLoader color="#FFF" size={26}/>
														</div>
													)
											}
										</a>
									)
									: (
										<a className="player__interaction-button waves-button"><MdFavorite
											size={24}
											color="#0B9A66"
										/>
										</a>
									)
							}
						</li>

						<li className="player__interaction-item player__interaction-item--download">
							{
								src
									? (
										<a
											className="player__interaction-button waves-effect waves-button"
											href={currentTrack.src}
											download={`${currentTrack.code} ${currentTrack.name}`}
										>
											<BiDownload size={24} color="#fff"/>
										</a>
									)
									: (
										<a className="player__interaction-button waves-button"><BiDownload
											size={24}
											color="#0B9A66"
										/>
										</a>
									)
							}
						</li>

						<li
							className="player__interaction-item player__interaction-item--cart"
						>
							{
								isBasket
									? (
										<a className="player__interaction-button waves-button is-active">
											<MdShoppingCart
											size={24}
											color="#0B9A66"
										/>
										</a>
									)
									: (
										<a
											onClick={addTrackToBasket}
											className="player__interaction-button waves-effect waves-button"
										>
											<MdShoppingCart size={24} color="#fff"/>
										</a>
									)
							}
						</li>
						<li className="player__interaction-item player__interaction-item--menu js-dropdown">
							{
								src && (
									<>
										<a
											className="player__interaction-button waves-effect waves-button js-dropdown-button"
											href="#"
										>
											<HiDotsVertical size={24}/>
										</a>
										<div className="player__interaction-content player__menu js-dropdown-content">
											<ul className="player__menu-list">
												<li style={{textAlign: "center"}}><a className="d-inline-block" onClick={setAlbumHandler}>Перейти
													в альбом
												</a>
												</li>
											</ul>
										</div>

									</>
								)
							}
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default memo(AudioPlayerBottom);
