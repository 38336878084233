import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import {useWindowSize} from 'react-use';
import CatalogPlaylistListItem from './CatalogPlaylistListItem';

const StyledList = styled.div`
display: grid;
align-items: center;
gap: 20px; 
margin-bottom: 20px;
${({rowSize}) => css`
grid-template-columns:  repeat(${rowSize}, 1fr);
`}
`;

const CatalogPlaylistList = () => {
	const {playlists} = useSelector((state) => state.catalog);
	const [rows, setRows] = useState([]);
	const [size, setSize] = useState(5);
	const {width} = useWindowSize();

	useEffect(() => {
		const result = playlists.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / size);
			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = [];
			}
			resultArray[chunkIndex].push(item);
			return resultArray;
		}, []);
		setRows(result);
	}, [playlists, size]);

	useEffect(() => {
		if (width > 1200) {
			setSize(5);
		} else if (width > 1000 && width <= 1200) {
			setSize(4);
		} else if (width > 800 && width <= 1000) {
			setSize(3);
		} else {
			setSize(2);
		}
	}, [width]);

	return (
		<>
			{
				!!rows.length && rows.map((row, idx) => {
					return (
						<StyledList key={idx} rowSize={size}>
							{
								row.map((playlist) => <CatalogPlaylistListItem key={playlist.id} playlist={playlist}/>)
							}
						</StyledList>
					);
				})
			}
		</>
	);
};

export default CatalogPlaylistList;
