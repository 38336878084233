import React from 'react';
import styled, {css} from 'styled-components';
import {BsPauseFill, BsPlayFill} from 'react-icons/bs';

const StyledButton = styled.button`
  ${(props) => props.disabled && css`
    pointer-events: none;
    background: #E1E1E1;
  `}
`;

const PlayBtn = ({play = false, onClick, disabled = false}) => {
	return (
		<StyledButton
			className="page__player-play is-paused flex-shrink-0 mr-3"
			type="button"
			onClick={onClick}
			disabled={disabled}
		>
			{
				play
					? <BsPauseFill size={21}/>
					: <BsPlayFill size={21} style={{marginLeft: '2px'}}/>
			}
			{

			}
		</StyledButton>
	);
};

export default PlayBtn;
