import React from 'react';
import styled, {css} from 'styled-components';

const StyledLink = styled.a`
  ${(props) => !props.href && css`
    pointer-events: none;
  `}`;

const DemoDownloadBtn = ({title = 'Демо', track, className = ''}) => {
	return (
		<>
			{
				!!track && (
					<StyledLink
						className={`button button--default waves-light button--waves button--${!!track.src ? 'success' : 'gray'} ${className}`}
						href={track.src}
						download={`${track.code} ${track.name}`}
					>
			<span className="button__content">
			{title}
			</span>
					</StyledLink>
				)
			}
		</>
	);
};

export default DemoDownloadBtn;

