import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setUserProperty} from '../../store/reducers/userSlice';
import SelectList from '../../components/SelectList';

const BasketProfileSelect = () => {
	const dispatch = useDispatch();
	const {paymentProfile, currentProfileId} = useSelector((state) => state.user);
	const [options, setOptions] = useState([]);

	const selectHandler = useCallback(({value}) => {
		dispatch(setUserProperty({property: 'currentProfileId', value}));
	}, [dispatch]);

	useEffect(() => {
		if (!!paymentProfile.length) {
			const options = paymentProfile.map(({id, name}) => {
				return {
					value: id,
					label: name,
				};
			});
			setOptions(options);
		}
	}, [paymentProfile]);
	useEffect(() => {
		if (options[0] && options[0].value) {
			selectHandler(options[0]);
		}
	}, [options, selectHandler]);

	if (!options[0]) {
		return null;
	}
	const value = options.find((option) => option.value === currentProfileId) || options[0];
	return (
		<SelectList
			options={options}
			onChange={selectHandler}
			placeholder="Выбрать профиль"
			width="370px"
			value={value}
		/>
	);
};

export default BasketProfileSelect;
