import axios from 'axios';

export default axios.create({
	baseURL: '/api/v1/',
	method: 'GET',
	headers: {
		'Cache-Control': 'no-cache',
		Pragma: 'no-cache',
		Expires: '0',
	},
})