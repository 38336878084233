import React, {forwardRef, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StringParam, useQueryParam} from 'use-query-params';
import Modal from '../../components/Modal';
import {createOrderSubscribe, createOrderTracks, deleteAllTrackBasket} from '../../store/actions/basketActions';

const BasketModal = forwardRef(({closeDialog}, ref) => {
	const dispatch = useDispatch();
	const {orderLink, payType} = useSelector((state) => state.basket);
	const [subscribeId] = useQueryParam('subscribeId', StringParam);

	const buyByAccount = useCallback(async () => {
		if (subscribeId) {
			await dispatch(createOrderSubscribe());
		} else {
			await dispatch(createOrderTracks());
		}
		dispatch(deleteAllTrackBasket());
		closeDialog();
	}, [subscribeId, closeDialog, dispatch]);

	const byOnline = () => {
		// dispatch(deleteAllTrackBasket());
		console.log('by online action');
	};

	useEffect(() => {
		if (orderLink) {
			window.location.href = orderLink;
		}
	}, [orderLink]);
	return (
		<Modal
			ref={ref}
			title={payType === 1 ? 'Оплата по счету' : 'Онлайн оплата'}
			onClose={closeDialog}
		>
			<p>
				{payType === 1
					? 'Сформировать счет и документы для безналичной оплаты.'
					: 'Вы будете переадресованы на страницу оплаты банковской картой.'}
			</p>
			<p className="title--weight--bold">Вы уверены?</p>
			<div className="d-flex mt-5">
				<button
					type="button"
					className="button button--default button--success waves-light mr-3 button--waves"
					onClick={payType === 1 ? buyByAccount : byOnline}
				>
					<span className="button__content">Да</span>
				</button>
				<button
					className="button button--default button--gray waves-light button--waves"
					type="button"
					onClick={closeDialog}
				>
					<span className="button__content">Нет</span>
				</button>
			</div>
		</Modal>
	);
});

export default BasketModal;
