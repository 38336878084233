import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import {useSelector} from 'react-redux';
import ReactWaves from '@dschoon/react-waves';
import {getTimeString} from '../../utils/date-utils';
import {usePlayer} from './context/playerContext';
import PlayBtn from '../../components/PlayBtn';

const options = {
	barHeight: 1,
	barWidth: 6,
	barGap: 1,
	cursorWidth: 0,
	height: 40,
	hideScrollbar: true,
	progressColor: '#10DB92',
	responsive: true,
	waveColor: '#C7C7C7',
};

const AudioPlayer = ({track, isVisible}) => {
	const player = useRef();
	const [isActive, setIsActive] = useState(false);

	const {currentTrack} = useSelector((state) => state.player);
	const {
		playing,
		position,
		progress,
		togglePlayPause,
		loadTrack,
		nextTrack,
		preparePeaks,
		setProgress,
		setProgress2,
		barHeight,
		barWidth,
		barGap,
		play,
		setPlay,
	} = usePlayer();

	const toggleTrackHandler = useCallback(() => {
		if (track.src && !isActive) {
			loadTrack(track);
			setPlay(true);
		}
	}, [isActive, loadTrack, setPlay, track]);

	useEffect(() => {
		setIsActive(currentTrack.id === track.id);
	}, [track, currentTrack]);

	useEffect(() => {
		if (player.current) {
			if (!isActive) {
				player.current.seekTo(0);
			} else {
				const time = progress / 100;
				if (time >= 1) {
					nextTrack();
				} else {
					player.current.seekTo(time);
				}
			}
		}
	}, [isActive, nextTrack, progress]);

	useEffect(() => {
		if (isActive) {
			const player = document.querySelector(`.react-waves-${track.id}`);
			const wave = player.getElementsByTagName('wave');
			if (wave[1]) {
				wave[1].style.width = `${progress}%`;
			}
		}
	}, [isActive, track, progress, position]);

	useEffect(() => {
		if (isActive) {
			const wave = document.getElementById(`react-waves-${track.id}`);
			wave.addEventListener('click', (e) => {
				e.preventDefault();
				const rect = e.target.getBoundingClientRect();
				const percent = ((e.clientX - rect.left) / rect.width) * 100;
				setProgress(percent);
				setProgress2(percent);
			});
		}
	}, [isActive, track, setProgress, setProgress2]);

	return (
		<div className="page__player-duration d-flex align-items-center">
			<div
				onClick={toggleTrackHandler}
			>
				<PlayBtn
					play={playing && isActive && play}
					onClick={togglePlayPause}
					disabled={!track.src}
				/>
			</div>
			<div className="page__player-duration-current text--small js-page-player-song-duration-current">
				{isActive && !!position ? <span>{getTimeString(position.toFixed())}</span>
					: <span style={{color: '#C7C7CA'}}>00:00</span>}
			</div>
			<div className="player__oscillogram" style={{position: 'relative'}}>
				{
					track.oscillogram && track.oscillogram.length > 1
						? (
							<ReactWaves
								key={barHeight + barWidth + barGap}
								audioFile="/"
								className={`react-waves react-waves-${track.id}`}
								audioPeaks={preparePeaks(track.oscillogram)}
								options={{
									...options,
									barHeight,
									barWidth,
									barGap,
								}}
								volume={0}
								zoom={1}
								playing={false}
							/>
						)
						: (
							<ReactWaves
								key={barHeight + barWidth + barGap}
								audioFile={track.src}
								className={`react-waves react-waves-${track.id}`}
								options={{
									...options,
									barHeight,
									barWidth,
									barGap,
								}}
								volume={0}
								zoom={1}
								playing={false}
							/>
						)
				}
				<div
					id={`react-waves-${track.id}`}
					style={{
						position: 'absolute',
						height: '100%',
						width: '100%',
						zIndex: 9,
						top: 0,
					}}
				/>
			</div>
			<div
				className="page__player-duration-total text--small js-page-player-song-duration-total"
			>{getTimeString(track.length)}</div>
		</div>
	);
};

export default AudioPlayer;
