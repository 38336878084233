import {createAsyncThunk} from '@reduxjs/toolkit';
// import get from '../../rest/get';
// import post from '../../rest/postLocal';
import get from '../../rest/getLocal';

// COLLECTIONS ACTIONS
export const getCollections = createAsyncThunk(
	'catalog/getCollections',
	async ({page = 1, limit = 50, filters = {}}, {rejectWithValue, dispatch}) => {
		const url = 'getCollections';
		let params = {page, limit};
		if (!!Object.keys(filters).length) {
			params = {...params, ...filters};
			if (params.bpm_max) {
				delete params.bpm_max;
			}
			if (params.bpm_min) {
				delete params.bpm_min;
			}
		}
		try {
			const {data} = await get({
				url,
				params,
			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

// TRACKS ACTIONS

export const searchTracks = createAsyncThunk(
	'catalog/searchTracks',
	async ({
		page = 1, limit = 10, filters = {},
		album_code = null, playlistId = null,
		name = null,
		sort = null,
	}, {rejectWithValue, dispatch}) => {
		let url = 'getTracks';
		let params = {
			page, limit, sort, album_code, ...filters,
		};
		if (!!name) {
			url = 'getTracks/filter';
			params = {...params, name};
		}
		try {
			const {data} = await get({
				url,
				params,
			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getTrack = createAsyncThunk(
	'catalog/getTrack',
	async ({trackId}, {rejectWithValue, dispatch}) => {
		const url = 'getTrack';
		const params = {code: trackId};
		try {
			const {data} = await get({
				url,
				params,
			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getTracks = createAsyncThunk(
	'catalog/getTracks',
	async ({
		page = 1, limit = 50, filters = {},
		album_id = null, playlistId = null,
		name = null, infinity = false,
		sort = null,
	}, {rejectWithValue, dispatch}) => {
		let url = 'getTracks/filter';
		let params = {page, limit, sort};
		// if ((!!Object.keys(filters).length && !playlistId) || !!album_id || !!playlistId || !!name) {
		// 	url = 'getTracks/filter';
		// 	params = {
		// 		...params, ...filters, album_code: album_id, playlistId, name,
		// 	};
		// }

		if (!!Object.keys(filters).length && !playlistId) {
			url = 'getTracks/filter';
			params = {...params, ...filters};
		}
		if (!!album_id) {
			url = 'getTracks/filter';
			params = {...params, album_code: album_id};
		}
		if (!!playlistId) {
			url = 'getTracks/filter';
			params = {...params, playlistId};
		}
		if (!!name) {
			url = 'getTracks/filter';
			params = {...params, name};
		}

		try {
			const {data} = await get({
				url,
				params,
			});
			return {data, infinity};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

// ALBUMS ACTIONS
export const getAlbumInfo = createAsyncThunk(
	'catalog/getAlbumInfo',
	async ({id}, {rejectWithValue, dispatch}) => {
		const url = 'getAlbumInfo';
		const params = {code: id};
		try {
			const {data} = await get({
				url,
				params,
			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getAlbums = createAsyncThunk(
	'catalog/getAlbums',
	async ({
		page = 1, limit = 1000,
		filters = {}, collectionId = null, infinity = false,
		sort = null,
	}, {rejectWithValue, dispatch}) => {
		let url = 'getAlbums';
		let params = {
			page, limit, sort, collectionId,
		};
		if (!!Object.keys(filters).length) {
			url = 'getAlbums/filter';
			params = {...params, ...filters};
		}

		try {
			const {data} = await get({
				url,
				params,
			});
			if (!!data.data) {
				return {data, infinity};
			}
			return {
				data: {
					data: [],
					nav: {
						curPage: 1,
						allPage: 1,
					},
				},
				infinity,
			};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

// PLAYLISTS ACTIONS
export const getPlaylists = createAsyncThunk(
	'catalog/getPlaylists',
	async ({page = 1, limit = 1000, infinity = false}, {rejectWithValue, dispatch}) => {
		const url = 'getPlaylists';
		const params = {page, limit};
		try {
			const {data} = await get({
				url,
				params,
			});
			return {data, infinity};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getPlaylistInfo = createAsyncThunk(
	'catalog/getPlaylistInfo',
	async ({id}, {rejectWithValue, dispatch}) => {
		const url = 'getPlaylistInfo';
		const params = {id};
		try {
			const {data} = await get({
				url,
				params,
			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);
