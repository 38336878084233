import React, {
	useCallback, useMemo, useRef,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useWindowSize} from 'react-use';
import {useFilter} from './context/filterContext';
import {useCatalog} from './context/catalogContext';
import CatalogAlbumDetail from './CatalogAlbumDetail';
import {clearTracks, clearAlbum} from '../../store/reducers/catalogSlice';
import CatalogSort from './CatalogSort';
import CatalogAlbumList from './CatalogAlbumList';

const CatalogAlbums = () => {
	const ref = useRef();
	const dispatch = useDispatch();
	const {getFilters} = useFilter();
	const {albumId, setAlbum, getCurrentPageData, setPageHandler} = useCatalog();
	const {height} = useWindowSize();
	const {albums, pageNav} = useSelector((state) => state.catalog);
	const filters = useMemo(() => {
		return {...getFilters()};
	}, [getFilters]);

	const backBtnHandler = () => {
		setAlbum(undefined);
		dispatch(clearTracks());
		dispatch(clearAlbum());
		const prevTrack = localStorage.getItem('prevTrack');
		if (prevTrack) {
			const prevTrack = localStorage.clear('prevTrack');
			setPageHandler('tracks')();
		}
	};

	const getData = useCallback(() => {
		getCurrentPageData({
			filters,
			infinity: true,
			page: pageNav.curPage + 1,
		});
	}, [getCurrentPageData, filters, pageNav]);

	return (
		<div className="catalog__content catalog__content--albums">
			{
				albumId
					? <CatalogAlbumDetail onBack={backBtnHandler}/>
					: (
						<>
							<CatalogSort/>
							<div
								id="scrollableDiv"
								className="Catalog__content-scroll-container mt-4"
								style={{
									height: height - 380,
									overflow: 'auto',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<InfiniteScroll
									ref={ref}
									dataLength={albums.length}
									next={getData}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									hasMore={pageNav.curPage !== pageNav.allPage}
									loader={<h3>Загрузка ...</h3>}
									scrollableTarget="scrollableDiv"
								>
									<CatalogAlbumList/>
								</InfiniteScroll>
							</div>
						</>
					)

			}
		</div>
	);
};

export default CatalogAlbums;
