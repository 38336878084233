import React from 'react';
import {MdOutlineArrowBackIosNew} from 'react-icons/md';
import styled from 'styled-components';

const StyledBtn = styled.div`
  cursor: pointer;
`;

const BackBtn = ({onClick}) => {
	return (
		<StyledBtn
			onClick={onClick}
			className="text--small card__back"
		>
			<MdOutlineArrowBackIosNew/>
			<span>Назад</span>
		</StyledBtn>
	);
};

export default BackBtn;
