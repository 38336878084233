import {createAsyncThunk} from '@reduxjs/toolkit';
// import post from '../../rest/postLocal';
import get from '../../rest/getLocal';

export const getFilterData = createAsyncThunk(
	'filters/getFilterData',
	async (__, {rejectWithValue, dispatch}) => {
		try {
			const {data} = await get({
				url: 'getFilterData',
			});
			return data;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);
