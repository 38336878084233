import {createSlice} from '@reduxjs/toolkit';
import {
	getAlbumInfo, getAlbums, getCollections, getPlaylistInfo, getPlaylists, getTrack, getTracks, searchTracks,
} from '../actions/catalogActions';

const initialState = {
	nav: [
		{
			id: 'collections',
			title: 'Коллекции',
		},
		{
			id: 'albums',
			title: 'Альбомы',
		},
		{
			id: 'tracks',
			title: 'Треки',
		},
		{
			id: 'playlists',
			title: 'Плейлисты',
		},
	],
	albums: [],
	collections: [],
	tracks: [],
	trackSearch: [],
	playlists: [],
	albumInfo: {},
	sort: 'name_desc',
	name: '',
	playlistInfo: {},
	pageNav: {},
	lastAlbumOffset: 0,
	lastCollectionOffset: 0,
	isLoading: false,
	hasNextPage: true,
	error: null,
};

const setError = (state, action) => {
	state.isLoading = false;
	state.error = action.payload;
};
const setPending = (state) => {
	state.isLoading = true;
	state.error = null;
};

const catalogSlice = createSlice({
	name: 'catalog',
	initialState,
	reducers: {
		clearCatalog: (state) => {
			state.albums = [];
			state.collections = [];
			state.tracks = [];
			state.albumInfo = {};
		},
		clearPlayListInfo: (state) => {
			state.playlistInfo = {};
		},
		clearAlbum: (state) => {
			state.albumInfo = {};
		},
		clearTracks: (state) => {
			state.tracks = [];
		},
		setSort: (state, {payload}) => {
			state.payload = payload;
		},
		resetPageNav: (state) => {
			state.pageNav = {curPage: 1, allPage: 1}
		},
		setCatalogProperty: (state, {payload}) => {
			state[payload.property] = payload.value
		},
		setSearchedTrack: (state, {payload}) => {
			state.tracks = [payload.track];
		},
		setLastAlbumOffset: (state, {payload}) => {
			state.lastAlbumOffset = payload;
		},
		setLastCollectionOffset: (state, {payload}) => {
			state.lastCollectionOffset = payload;
		}
	},
	extraReducers: {
		// PENDING
		[getAlbums.pending]: setPending,
		[getCollections.pending]: setPending,
		[getTracks.pending]: setPending,
		[getPlaylists.pending]: setPending,
		[getPlaylistInfo.pending]: setPending,

		//FULFILLED
		[getAlbums.fulfilled]: (state, {payload}) => {
			state.isLoading = false;
			if (payload.data.data && Array.isArray(payload.data.data)) {
				state.pageNav = payload.data.nav;
				if (payload.infinity) {
					state.albums = [...state.albums, ...payload.data.data];
				} else {
					state.albums = payload.data.data;
				}
			}
		},
		[getCollections.fulfilled]: (state, {payload}) => {
			state.isLoading = false;
			state.pageNav = payload.data.nav;
			if (payload.data.data && Array.isArray(payload.data.data)) {
				if (payload.infinity) {
					state.collections = [...state.collections, ...payload.data.data];
				} else {
					state.collections = payload.data.data;
				}
			}
		},
		[getAlbumInfo.fulfilled]: (state, {payload}) => {
			if (typeof payload.data === 'object' &&
				!Array.isArray(payload.data) &&
				payload.data !== null) {
				state.albumInfo = payload.data;
			}
		},
		[getTracks.fulfilled]: (state, {payload}) => {
			state.isLoading = false;
			state.pageNav = payload.data.nav
			if (payload.data.data && Array.isArray(payload.data.data)) {
				if (payload.infinity) {
					state.tracks = [...state.tracks, ...payload.data.data];
				} else {
					state.tracks = payload.data.data;
				}
			} else {
				state.tracks = [];
			}
		},
		[searchTracks.fulfilled]: (state, {payload}) => {
			state.isLoading = false;
			state.pageNav = payload.data.nav;
			if (payload.data?.data && Array.isArray(payload.data.data)) {
				state.tracks = payload.data.data;
				// state.trackSearch = payload.data.data;
			} else {
				state.tracks = [];
				// state.trackSearch = [];
			}
		},
		[getTrack.fulfilled]: (state, {payload}) => {
			state.isLoading = false;
			state.pageNav = {curPage: 1, allPage: 1}
			if (payload.data?.data) {
				state.tracks = payload.data.data;
			}
		},
		[getPlaylists.fulfilled]: (state, {payload}) => {
			state.isLoading = false;
			state.pageNav = {curPage: 1, allPage: 1}
			// к плейлистов пока нет пагинации
			if (payload?.data && Array.isArray(payload.data)) {
				if (payload.infinity) {
					state.playlists = [...state.playlists, ...payload.data];
				} else {
					state.playlists = payload.data;
				}
			}
		},
		[getPlaylistInfo.fulfilled]: (state, {payload}) => {
			state.isLoading = false;
			if (typeof payload.data === 'object' &&
				!Array.isArray(payload.data) &&
				payload.data !== null) {
				state.playlistInfo = payload.data;
				state.tracks = payload.data.tracks
			}
		},

		// REJECTED
		[getAlbums.rejected]: setError,
		[getCollections.rejected]: setError,
		[getAlbumInfo.rejected]: setError,
		[getTracks.rejected]: setError,
		[getPlaylists.rejected]: setError,
		[getPlaylistInfo.rejected]: setError,
	},
});

export const {
	clearCatalog, clearTracks,
	resetPageNav, setCatalogProperty,
	setSearchedTrack, clearAlbum, clearPlayListInfo,
	setLastAlbumOffset, setLastCollectionOffset,
} = catalogSlice.actions;

export default catalogSlice.reducer;
