import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setUserProperty} from '../../../store/reducers/userSlice';
import SelectList from '../../../components/SelectList';

const ProfilesSelect = () => {
	const dispatch = useDispatch();
	const {paymentProfile, currentProfileId, result} = useSelector((state) => state.user);
	const [options, setOptions] = useState([]);
	const [value, setValue] = useState(null);

	const selectHandler = ({value}) => {
		dispatch(setUserProperty({property: 'currentProfileId', value}));
		dispatch(setUserProperty({property: 'currentProfileType', value: null}));
	};

	useEffect(() => {
		if (!!paymentProfile.length) {
			const options = paymentProfile.map(({id, name}) => {
				return {
					value: id,
					label: name,
				};
			});
			setOptions(options);
		} else {
			setOptions([]);
		}
	}, [paymentProfile]);

	useEffect(() => {
		const option = options.find((option) => +option.value === +currentProfileId);
		setValue(option || null);
	}, [currentProfileId, options]);

	return (
		<SelectList
			options={options}
			onChange={selectHandler}
			placeholder="Выбрать профиль"
			value={value}
			noOptionsMessage="У вас нет профиля. Создайте новый профиль"
		/>
	);
};

export default ProfilesSelect;
