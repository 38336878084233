import { configureStore } from '@reduxjs/toolkit';
import filterSlice from './reducers/filtersSlice';
import catalogSlice from './reducers/catalogSlice';
import playerSlice from './reducers/playerSlice';
import userSlice from './reducers/userSlice';
import userListSlice from './reducers/userListSlice';
import subscribeSlice from './reducers/subscribeSlice';
import basketSlice from './reducers/basketSlice';

export default configureStore({
	reducer: {
		filters: filterSlice,
		catalog: catalogSlice,
		player: playerSlice,
		user: userSlice,
		userList: userListSlice,
		subscribe: subscribeSlice,
		basket: basketSlice,
	},
});
