/* eslint-disable */ 
import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import styled from 'styled-components';
import {useWindowSize} from 'react-use';
import {useDispatch, useSelector} from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useFilter} from './context/filterContext';
import {useCatalog} from './context/catalogContext';
import CatalogSort from './CatalogSort';
import CatalogTracksItem from './CatalogTracksItem';
import Loader from '../../components/Loader';
import {setPopover} from '../../store/reducers/userListSlice';
import { getTracks } from '../../store/actions/catalogActions';

const StyledLoader = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	background: rgba(255,255,255,.3);
	display:flex;
	justify-content: center;
	align-items: center;
`;

const CatalogTracks = ({showHeader = true, height}) => {
	const ref = useRef();
	const tracksRef = useRef();
	const {isAuth} = useSelector((state) => state.user);
	const {height: windowHeight} = useWindowSize();
	const dispatch = useDispatch();
	const {getFilters, name} = useFilter();
	const {getCurrentPageData} = useCatalog();
	const {tracks, pageNav, isLoading} = useSelector((state) => state.catalog);
	const filters = useMemo(() => {
		return {...getFilters()};
	}, [getFilters]);
	const scrollableDiv = document.querySelector('.infinite-scroll-component');

	const getData = useCallback(() => {
		getCurrentPageData({
			filters,
			infinity: true,
			page: pageNav.curPage + 1,
			name,
		});
	}, [getCurrentPageData, filters, pageNav, name]);

	const scrollHandler = () => {
		dispatch(setPopover(null));
	};

	useEffect(() => {
		if (ref.current) {
			ref.current.el.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}, [filters]);

	if (!!scrollableDiv && !isAuth) {
		const scrollTracks = scrollableDiv.scrollTop;
		localStorage.setItem('trackScroll', scrollTracks);
	}

	useEffect(() => {
		const track = +localStorage.getItem('tacksCount');
		if (!!track && track === tracks.length && isAuth) {
			localStorage.removeItem('tacksCount');
		}
	}, [tracks]);

	useEffect(() => {
		const track = +localStorage.getItem('tacksCount');
		const scroll = +localStorage.getItem('trackScroll');
		if (!track && isAuth && !!scroll) {
			
			scrollableDiv.scrollTo({
				top: scroll,
				behavior: 'smooth',
			});
		}
	}, [tracks]);

	const scrollAfterAuth = async() => {
			const track = +localStorage.getItem('tacksCount');
			if(track) {
				const args = {page: 1, limit: track}
				await dispatch(getTracks({...args}));
			}
			// console.log('scroll', +scroll)
			// if (ref.current) {
			// 	console.log('el',ref.current.el);
			// 	ref.current.el.scrollTo({
			// 		top: 5000,
			// 		behavior: 'smooth',
			// 	});
			// 	localStorage.removeItem('trackScroll');
				
			// }
	}

	useEffect(() => {
		if (isAuth) {
			scrollAfterAuth();
		}
	}, [isAuth]);

	const scrollHeight = height || windowHeight - 345;

	return (
		<div>
			{
				showHeader && <CatalogSort/>
			}
			{
				isLoading && (
					<StyledLoader>
						<Loader/>
					</StyledLoader>
				)
			}
			<div
				id="scrollableDiv"
				className="Catalog__content-scroll-container mt-4"
				ref={tracksRef}
				style={{
					height: scrollHeight,
					// width: 1530,
					overflow: !isLoading ? 'auto' : 'hidden',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				{
					!tracks.length && !isLoading
						? (
							<div
								className="cart__list-header"
								style={{fontSize: 18}}
							>
								<div>Треки не найдены</div>
							</div>
						)
						: (
							<InfiniteScroll
								ref={ref}
								dataLength={tracks.length}
								next={getData}
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
								hasMore={pageNav.curPage !== pageNav.allPage}
								loader={<h3>Загрузка ...</h3>}
								scrollableTarget="scrollableDiv"
								onScroll={scrollHandler}
							>
								{
									tracks.map((track, idx) => {
										const num = idx < 9 ? `0${idx + 1}` : idx + 1;
										return (
											<CatalogTracksItem
												key={track.id}
												track={track}
												num={num}
											/>
										);
									})
								}
							</InfiniteScroll>
						)
				}

			</div>
		</div>
	);
};

export default CatalogTracks;
