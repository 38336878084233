import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {getImagePath} from '../../utils/image-utils';
import {useCatalog} from './context/catalogContext';
import {resetPageNav, setLastAlbumOffset, setLastCollectionOffset} from '../../store/reducers/catalogSlice';
import {useWindowSize} from 'react-use';

const StyledLink = styled.div`
  cursor: pointer;
`;

const CatalogCollectionList = ({collections}) => {
	const ref = useRef();
	const {setCollection} = useCatalog();
	const dispatch = useDispatch();
	const {height} = useWindowSize();
	const {lastCollectionOffset} = useSelector((state) => state.catalog);

	const setCollectionHandler = (id, index) => {
		dispatch(setLastAlbumOffset(0));
		setCollection(id);
		dispatch(resetPageNav());
		const {height} = ref.current.getBoundingClientRect();
		const row = Math.round((index + 1) / 2);
		localStorage.setItem('test', height);
		if (ref.current && row > 2) {
			const {height} = ref.current.getBoundingClientRect();
			dispatch(setLastCollectionOffset((height + 20) * row - (height + height / 2)));
		}
	};

	useEffect(() => {
		if (lastCollectionOffset) {
			const $scrollableDiv = document.getElementById('scrollableDiv');
			if ($scrollableDiv) {
				$scrollableDiv.scrollTo({
					top: lastCollectionOffset,
					behavior: 'smooth',
				});
				dispatch(setLastCollectionOffset(0));
			}
		}
	}, []);

	return (
		<div
			id="scrollableDiv"
			className="Catalog__content-scroll catalog__content catalog__content--collections mt-5"
			style={{
				height: '90%',
			}}
		>
			<ul className="catalog__content-row">
				{
					collections.map((collection, index) => {
						const {
							id, poster, name, description, tracksQuantity, albumsQuantity,
						} = collection;
						const image = getImagePath(poster);

						return (
							<li key={id} className="catalog__content-col1" ref={ref}>
								<StyledLink onClick={() => setCollectionHandler(id, index)}>
									<section className="card__collection">
										<div className="card__collection-image">
											<div className="page__image">
												<img
													className="lazy__image lazy__image--static is-entered is-loaded"
													alt={name}
													src={image}
												/>

											</div>
										</div>
										<div className="card__collection-content">
											<h3 className="title title--weight--medium text--middle pb-3">
												{name}
											</h3>
											<div
												className="card__collection-description text--gray text--small"
											>
												{description}
											</div>
											<p className="text--small">
											<span className="pr-4">
												<span className="title--weight--medium">
													Треки:
												</span>
                              					<output
													className="title--weight--light"
												>
													&nbsp;{tracksQuantity}
                                   </output>
           </span>
												<span>
												<span
													className="title--weight--medium"
												>
													Альбомы:
												</span>
                            				  <output className="title--weight--light">
												  &nbsp;{albumsQuantity}
                                  </output>
            </span>
											</p>
										</div>
									</section>
								</StyledLink>
							</li>
						);
					})
				}
			</ul>
		</div>
	);
};

export default CatalogCollectionList;
