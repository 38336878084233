import React, {useCallback, useEffect, useState} from 'react';
import {createSliderWithTooltip, Range} from 'rc-slider';
import Cleave from 'cleave.js/react';
import {useFilter} from './context/filterContext';
import {getSeconds} from '../../utils/date-utils';
import FilterList from '../../components/FilterList';
import FilterTitle from '../../components/FilterTitle';

const RangeWithTooltip = createSliderWithTooltip(Range);
const CatalogFilterChoiceListItem = ({filter}) => {

	const {
		choices, ranges, length, setLength,
		radios, setRadios,
		setChoices, setRanges,
		searches, setSearches,
	} = useFilter();
	const [defaultRange, setDefaultRange] = useState([0, 0]);
	const [defaultLength, setDefaultLength] = useState({
		min: '00:00',
		max: '00:00',
	});

	const convertTime = (time) => {
		if (time === '' || time === ' ') {
			return 0;
		}
		return +(+time.split(':')[0] * 60 + +time.split(':')[1]);
	};

	const convertSeconds = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
	};

	const [rangeValue, setRangeValue] = useState(ranges.bpm);
	const [rangeValueTime, setRangeValueTime] = useState([0, 1000]);

	const getVoiceType = (choice) => {
		let radioName = '';
		switch (choice.toString().toLowerCase()) {
			case 'yes':
				radioName = 'Да';
				break;
			case 'no':
				radioName = 'Нет';
				break;
			default:
				radioName = choice;
		}
		return radioName;
	};

	const checkboxHandler = (evt) => {
		if (evt.target.checked) {
			if (choices[filter.id]) {
				setChoices({
					...choices,
					[filter.id]: [...choices[filter.id].filter((choice) => choice !== evt.target.value), evt.target.value],
				});
			} else {
				setChoices({...choices, [filter.id]: [evt.target.value]});
			}
			setSearches([...searches.filter((search) => search.title !== evt.target.value), {
				title: evt.target.value,
				type: filter.id,
			}]);
		} else {
			setChoices({
				...choices,
				[filter.id]: [...choices[filter.id].filter((choice) => choice !== evt.target.value)],
			});
			setSearches([...searches.filter((search) => search.title !== evt.target.value)]);
		}
	};

	useEffect(() => {
		if (choices?.genre?.length > 2) {
			setChoices({
				...choices,
				genre: choices.genre.slice(-2),
			});
			const genre = searches.filter((el) => el.type === "genre").slice(-2);
			setSearches([
				...searches.filter((search) => search.type !== "genre"), ...genre
			]);
		}
	}, [choices, searches, setChoices]);

	const rangeHandler = useCallback((value) => {
		setRanges({...ranges, [filter.id]: value});
		setSearches([...searches.filter((search) => search.type !== filter.id), {
			title: `${filter.id.toUpperCase()} ${value[0]}-${value[1]}`,
			type: filter.id,
		}]);
	}, [filter.id, ranges, searches, setRanges, setSearches]);

	const timeTransform = (value) => {
		const min = +value.split(':')[0] > 0 ? +value.split(':')[0] + ' мин' : '';
		const sec = +value.split(':')[1] > 0 ? +value.split(':')[1] + ' сек' : '';
		return `${min} ${sec}`
	}

	const lengthHandler = useCallback((value, type) => {
		if (value !== '00:00') {
			const newLength = {...length, [type]: value};
			const lengthSearch = [...searches.filter((search) => search.type !== `${filter.id}_${type}`), {
				title: `Длина ${type === 'min' ? 'от' : 'до'} ${timeTransform(value)}`,
				type: `${filter.id}_${type}`,
			}];
			if (newLength[type] !== length[type]) {
				setLength(newLength);
				setSearches(lengthSearch);
			}
		}
	}, [filter.id, length, searches, setLength, setSearches]);

	const radioHandler = useCallback((evt) => {
		if (evt.target.value === filter.defaultValue) {
			setRadios({...radios, [filter.id]: ''});
			setSearches([...searches.filter((search) => search.type !== filter.id)]);
		} else {
			setRadios({...radios, [filter.id]: evt.target.value});
			setSearches([...searches.filter((search) => search.type !== filter.id), {
				title: `Вокал: ${getVoiceType(evt.target.value)}`,
				type: filter.id,
			}]);
		}
	}, [filter.defaultValue, filter.id, radios, searches, setRadios, setSearches]);

	useEffect(() => {
		if (!searches.find((el) => el.type === 'has_vocals')) {
			setRadios({has_vocals: ''});
		}
	}, [searches]);

	const handleRangeValue = (value) => {
		setRangeValue(value);
	};

	const lengthFocusOutHandler = useCallback((value, type) => {
		if (value !== '00:00') {
			let time = value;
			if (value.length === 1) {
				time = `${value}0:00`;
			} else if (value.length === 3) {
				time = `${value}00`;
			} else if (value.length === 4) {
				time = `${value}0`;
			}
			const newLength = {...length, [type]: time};
			if (newLength[type] !== length[type]) {
				setRangeValueTime([convertTime(newLength.min), convertTime(newLength.max)])
				setLength(newLength);
			}
		}
	}, [length, setLength]);

	useEffect(() => {
		if (filter.type === 'range') {
			if (filter.defaultValue && filter.defaultValue.length === 2) {
				setDefaultRange(filter.defaultValue);
			} else {
				setDefaultRange([filter.min, filter.max]);
			}
		}
		if (filter.type === 'length') {
			setDefaultLength({min: filter.min, max: filter.max});
		}
		// if (filter.type === 'radio') {
		// 	if (!radios[filter.id]) {
		// 		setRadios({...radios, [filter.id]: ''});
		// 	}
		// }
	}, [filter]);

	const handleRange = (value) => {
		setRangeValueTime(value);
	};

	const rangeHandlerTime = (value) => {
		lengthHandler(convertSeconds(value[0]), 'min');
		lengthHandler(convertSeconds(value[1]), 'max');
	};

	return (
		<FilterList>
			{
				filter.type === 'checkbox'
				&& (
					<li
						className="catalog__filter-settings-item js-catalog-filter--item"
						style={{
							opacity: 1,
							visibility: 'visible',
						}}
					>
						<p className="text--small title--weight--medium pb-4">{filter.description || filter.title}</p>
						<ul
							className="catalog__filter-settings-item-inner js-smooth-scroll-target"
							style={{overflowY: 'auto'}}
						>
							{
								filter.choices.map((choice) => (
									<li key={choice} className="mb-3">
										<label
											className="checkbox js-catalog-filter--checkbox d-block"
										>
											<input
												className="checkbox__input"
												type="checkbox"
												onChange={(evt) => checkboxHandler(evt)}
												checked={choices[filter.id] && choices[filter.id].includes(choice)}
												value={choice}
											/>
											<span
												className="checkbox__label"
											>
											{choice}
           </span>
										</label>
									</li>
								))
							}
						</ul>
					</li>
				)
			}
			{
				filter.type === 'range'
				&& (
					<li className="catalog__filter-settings-item js-catalog-filter--item js-catalog-filter--range-parent">
						<p className="text--small title--weight--medium pb-4">{filter.title}</p>
						<div className="row pb-4 justify-content-center flex-nowrap no-gutters">
							{/* <div className="col-auto">
								<FilterTitle>{filter.description || filter.title}</FilterTitle>
							</div> */}
							<div className="col-auto">
								<p className="catalog__filter-range-output text--small">
									<output
										className="js-catalog-filter--range-output--from"
									>
										{ranges[filter.id] ? ranges[filter.id][0] : defaultRange[0]}
									</output>
									&nbsp;-&nbsp;
									<output className="js-catalog-filter--range-output--to">
										{ranges[filter.id] ? ranges[filter.id][1] : defaultRange[1]}
									</output>
								</p>
							</div>
						</div>
						<ul className="catalog__filter-settings-item-inner">
							<li className="mb-3">
								<div className="catalog__filter-range catalog__filter-substrate">
									<RangeWithTooltip
										allowCross={false}
										defaultValue={filter.defaultValue}
										value={rangeValue}
										onChange={handleRangeValue}
										onAfterChange={rangeHandler}
										min={filter.min}
										max={filter.max}
										tipFormatter={(value) => value}
										trackStyle={{
											backgroundColor: '#E1E1E1',
										}}
										railStyle={{
											backgroundColor: '#E1E1E1',
										}}
										handleStyle={{
											border: '4px solid #10DB92',
										}}
									/>
								</div>
							</li>
						</ul>
					</li>
				)
			}
			{
				filter.type === 'length' && length
				&& (
					<li className="catalog__filter-settings-item js-catalog-filter--item">
						<p className="text--small title--weight--medium pb-4">{filter.description || filter.title}</p>
						<div className="catalog__filter-substrate">
							<div
								className="catalog__filter-timing js-catalog-filter--length d-flex justify-content-between align-items-center"
							>
								<div
									className="catalog__filter-timing-item d-flex align-items-center justify-content-center"
								>
									<Cleave
										placeholder="00:00"
										className="w-50 catalog__filter-timing-field js-catalog-filter--length-field js-keyboard-x-arrow-prevent-default"
										options={{
											time: true,
											timePattern: ['m', 's'],
										}}
										value={length.min || defaultLength.min}
										name="catalog-filter-length_min"
										//onFocus={(e) => e.currentTarget.select()}
										//onChange={(evt) => lengthHandler(evt.target.value, 'min')}
										onBlur={(evt) => lengthFocusOutHandler(evt.target.value, 'min')}
									/>
								</div>
								<div
									className="catalog__filter-timing-item d-flex align-items-center justify-content-center "
								>

									<Cleave
										placeholder="00:00"
										className="w-50 catalog__filter-timing-field js-catalog-filter--length-field js-keyboard-x-arrow-prevent-default"
										options={{
											time: true,
											timePattern: ['m', 's'],
										}}
										value={length.max || defaultLength.max}
										name="catalog-filter-length_min"
										//onFocus={(e) => e.currentTarget.select()}
										//onChange={(evt) => lengthHandler(evt.target.value, 'max')}
										onBlur={(evt) => lengthFocusOutHandler(evt.target.value, 'max')}
									/>

								</div>
							</div>
						</div>
						<ul className="catalog__filter-settings-item-inner">
							<li className="mb-3">
								<div className="catalog__filter-range catalog__filter-substrate">
									<RangeWithTooltip
										allowCross={false}
										defaultValue={[convertTime(defaultLength.min), convertTime(defaultLength.max)]}
										value={rangeValueTime}
										onChange={handleRange}
										onAfterChange={rangeHandlerTime}
										min={convertTime(defaultLength.min)}
										max={convertTime(defaultLength.max)}
										tipFormatter={(value) => convertSeconds(value)}
										trackStyle={{
											backgroundColor: '#E1E1E1',
										}}
										railStyle={{
											backgroundColor: '#E1E1E1',
										}}
										handleStyle={{
											border: '4px solid #10DB92',
										}}
									/>
								</div>
							</li>
						</ul>
					</li>
				)
			}
			{
				filter.type === 'radio'
				&& (
					<li className="catalog__filter-settings-item js-catalog-filter--item">
						<p className="text--small title--weight--medium pb-4">{filter.description || filter.title}</p>
						<ul
							className="catalog__filter-settings-item-inner js-smooth-scroll-target"
						>

							<li className="mb-3">
								<label className="radio js-catalog-filter--radio">
									<input
										className="radio__input"
										type="radio"
										checked={!radios[filter.id]}
										onChange={radioHandler}
										name="catalog-filter-has_vocals"
										value={filter.defaultValue}
									/>
									<span className="radio__label">{filter.defaultValue}</span>
								</label>
							</li>

							{
								filter.choices.map((choice) => {
									return (
										<li key={choice} className="mb-3">
											<label className="radio js-catalog-filter--radio">
												<input
													className="radio__input"
													type="radio"
													checked={radios[filter.id] === choice}
													onChange={radioHandler}
													name="catalog-filter-has_vocals"
													value={choice}
												/>
												<span className="radio__label">{getVoiceType(choice)}</span>
											</label>

										</li>
									);
								})
							}
						</ul>
					</li>
				)
			}
		</FilterList>
	);
};

export default CatalogFilterChoiceListItem;
