import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import {scrollBar} from '../styles/sc-mixins';

const StyledSelect = styled(Select)`
	& .react-select {
		&__menu-list {
			${scrollBar()}	
		}	
		&__control {
			width: ${({width}) => width || '100%'};
			min-height: 50px;
			&:hover {
				border-color: #10db92;
			}
			&--is-focused {
				border-color: #10db92!important;
				box-shadow: 0 0 0  1px rgb(16,219, 146);
			}
			&--menu-is-open {
				box-shadow: 0 0 0  1px rgb(16,219, 146);
			}	
			&-icon {
				position: absolute;
				right: 10px;
			}	
		}
		
		&__option {
			border-bottom: 1px solid #E3E3E3;			
			&--is-selected {
				background: #F5F5F8;
				color: #000;
			}
			&:hover  {
				background: #F5F5F8;
				cursor: pointer;
			}
		}
	}
`;

const SelectList = ({
	options, onChange, placeholder = '', width, defaultValue = null, value = '', noOptionsMessage = '',
}) => {
	return (
		<StyledSelect
			options={options}
			onChange={onChange}
			defaultValue={defaultValue}
			classNamePrefix="react-select"
			placeholder={placeholder}
			width={width}
			value={value}
			noOptionsMessage={() => noOptionsMessage}
		/>
	);
};

export default SelectList;
