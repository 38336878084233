import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SelectList from '../../../components/SelectList';
import {setUserProperty} from '../../../store/reducers/userSlice';

const ProfileTypes = () => {
	const dispatch = useDispatch();
	const {
		currentProfileType, profileTypes, result,
	} = useSelector((state) => state.user);
	const [value, setValue] = useState(null);

	const selectHandler = ({value}) => {
		dispatch(setUserProperty({property: 'currentProfileType', value}));
		dispatch(setUserProperty({property: 'currentProfileId', value: null}));
	};

	useEffect(() => {
		const option = profileTypes.find((option) => option.value === currentProfileType);
		setValue(option || null);
	}, [currentProfileType, profileTypes]);

	useEffect(() => {
		if (result) {
			setValue(null);
		}
	}, [dispatch, result]);

	return (
		<SelectList
			options={profileTypes}
			onChange={selectHandler}
			placeholder="Создать профиль"
			value={value}
		/>
	);
};

export default ProfileTypes;
