import {createAsyncThunk} from '@reduxjs/toolkit';
import qs from 'qs';
import post from '../../rest/postLocal';
import get from '../../rest/getLocal';

export const getSubscribe = createAsyncThunk(
	'user/subscribe/get',
	async (_, {rejectWithValue}) => {
		try {
			const {data} = await get({
				url: 'user/subscribe/get',
			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const addTrack = createAsyncThunk(
	'user/subscribe/addTrack',
	async ({trackId, subscribeId, type}, {rejectWithValue, dispatch}) => {
		try {
			const params = {
				trackId,
				subscribeId,
			};
			const {data} = await post({
				url: 'user/subscribe/addTrack',
				data: qs.stringify(params),
			});
			dispatch(getSubscribe());
			return {...data, type, trackId};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);
