import {createAsyncThunk} from '@reduxjs/toolkit';
import get from '../../rest/getLocal';

export const getPaymentProfile = createAsyncThunk(
	'user/paymentProfile/get',
	async (_, {rejectWithValue}) => {
		try {
			const {data} = await get({
				url: 'user/paymentProfile/get',

			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);
