import {createSlice} from '@reduxjs/toolkit';
import {
	addFavorite, addListened, deleteFavorite, getFavorite, getListened, getTracksIdsBySubscription,
} from '../actions/userListActions';

const initialState = {
	favorite: [],
	listened: [],
	subscribes: [],
	currentTrackPopover: null,
	isLoading: false,
	loadingTrackId: null,
	isUserListLoading: false,
	isListenedListLoading: false,
};

const setError = (state, action) => {
	state.isLoading = false;
	state.error = action.payload;
};
const setPending = (state) => {
	state.isLoading = true;
	state.error = null;
};
const setFulfilled = (state) => {
	state.isLoading = false;
	state.isUserListLoading = false;
	state.isListenedListLoading = false;
	state.error = null;
	state.loadingTrackId = null;
};

const userListSlice = createSlice({
	name: 'userList',
	initialState,
	reducers: {
		addFavoriteToState: (state, action) => {
			const tracks = state.favorite.filter(track => track.id !== action.payload.id);
			tracks.push(action.payload)
			state.favorite = tracks;
		},
		deleteFavoriteToState: (state, action) => {
			state.favorite = state.favorite.filter(track => track.id !== action.payload.id);
		},
		setPopover: (state, {payload}) => {
			state.currentTrackPopover = state.currentTrackPopover !== payload ? payload : null;
		},
	},
	extraReducers: {
		// PENDING
		[addFavorite.pending]: (state, action) => {
			state.loadingTrackId = action.meta.arg.trackId;
			state.isLoading = true;
		},
		[addListened.pending]: setPending,
		[deleteFavorite.pending]: (state, action) => {
			state.loadingTrackId = action.meta.arg.trackId;
			state.isLoading = true;
		},
		[getFavorite.pending]: (state) => {
			state.isUserListLoading = true;
			state.error = null;
		},
		[getListened.pending]: (state) => {
			state.isListenedListLoading = true;
			state.error = null;
		},
		[getTracksIdsBySubscription.pending]: (state) => {
			state.isListenedListLoading = true;
			state.error = null;
		},

		// FULFILLED
		[addFavorite.fulfilled]: setFulfilled,
		[addListened.fulfilled]: setFulfilled,
		[deleteFavorite.fulfilled]: setFulfilled,

		// Избранное
		[getFavorite.fulfilled]: (state, {payload}) => {
			if (payload.data && !payload.data.error) {
				if (Array.isArray(payload.data.data)) {
					state.favorite = payload.data.data;
				}
			}
			state.isUserListLoading = false;
		},
		[getTracksIdsBySubscription.fulfilled]: (state, {payload}) => {
			if (payload.data && !payload.data.error) {
				if (Array.isArray(payload.data.data)) {
					state.subscribes = payload.data.data;
				}
			}
			state.isListenedListLoading = false;
		},
		[getListened.fulfilled]: (state, {payload}) => {
			if (payload.data && !payload.data.error) {
				if (Array.isArray(payload.data.data)) {
					state.listened = payload.data.data;
				}
			}
			state.isListenedListLoading = false;
		},
		// REJECTED
		[addFavorite.rejected]: setError,
		[addListened.rejected]: setError,
		[deleteFavorite.rejected]: setError,
		[getFavorite.rejected]: setError,
		[getListened.rejected]: setError,
	},
});

export const {addFavoriteToState, deleteFavoriteToState, setPopover} = userListSlice.actions;
export default userListSlice.reducer;
