import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.div`
  display: flex;
  width: 200px;
  margin: 30px 0 0;

  img {
    width: 174px;
    height: 174px;
    object-fit: contain;
  }
`;

const ProfilesImage = ({ src, alt = '' }) => {
    return (
        <StyledImage>
            {
                src && <img src={src} alt={alt} />
            }
        </StyledImage>
    );
};

export default ProfilesImage;
