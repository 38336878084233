import React, {
	createContext, useCallback, useContext, useEffect,
} from 'react';
import {useQueryParam, StringParam} from 'use-query-params';
import {useDispatch, useSelector} from 'react-redux';
import {clearCatalog, resetPageNav} from '../../../store/reducers/catalogSlice';
import {
	getAlbumInfo,
	getAlbums, getCollections, getPlaylists, getTracks,
} from '../../../store/actions/catalogActions';
import { useFilter } from './filterContext';

export const CatalogContext = createContext(null);
export const useCatalog = () => {
	return useContext(CatalogContext);
};

const CatalogContextProvider = (props) => {
	const dispatch = useDispatch();
	const {nav, trackSearch} = useSelector((state) => state.catalog);
	const {isAuth} = useSelector((state) => state.user);
	const [type, setType] = useQueryParam('type', StringParam);
	const [collectionId, setCollectionId] = useQueryParam('collection', StringParam);
	const [albumId, setAlbumId] = useQueryParam('album', StringParam);
	const [playlistId, setPlaylistId] = useQueryParam('playlist', StringParam);
	const [trackId, setTrackId] = useQueryParam('trackId', StringParam);
	const [sort, setSort] = useQueryParam('sort', StringParam);
	const {children} = props;
	const setPageHandler = (value) => {
		return () => {
			setType(value);
			setCollectionId(undefined);
			setAlbumId(undefined);
			setPlaylistId(undefined);
			setTrackId(undefined);
			setSort(undefined);
			dispatch(clearCatalog());
			// dispatch(setCurrentTrack({}));
			dispatch(resetPageNav());
		};
	};
	const setCollection = (id) => {
		setCollectionId(id);
	};
	const setAlbum = (id) => {
		setAlbumId(id);
	};
	const setPlaylist = (id) => {
		setPlaylistId(id);
	};
	const setTrack = (id) => {
		setTrackId(id);
	};

	const getCurrentPageData = useCallback((args) => {
		switch (true) {
			case type === 'collections' && !albumId:
				if (!!collectionId) {
					dispatch(getAlbums({...args, collectionId, sort}));
				} else {
					dispatch(getCollections({...args}));
				}
				break;
			case type === 'albums' && !albumId:
				dispatch(getAlbums({...args, sort}));
				break;
			case type === 'playlists' && !playlistId:
				dispatch(getPlaylists({...args}));
				break;
			case type === 'tracks' && !trackId:
			case type === 'collections' && !!albumId:
			case type === 'albums' && !!albumId:
				if (!trackSearch.length || args.infinity) {
					if (window.location.href === 'http://zvukobaza.itfactory.site/catalog/?type=tracks' && !isAuth && args.page) {
						const count = args.page * 20;
						localStorage.setItem('tacksCount', count);
					}
					dispatch(getTracks({...args, album_id: albumId, sort}));
					dispatch(getAlbumInfo({id: albumId}));
				}
				break;
			default:
				console.log('nothing to show');
		}
	}, [type, albumId, collectionId, dispatch, sort, playlistId, trackId, trackSearch, isAuth]);

	useEffect(() => {
		if (!type) {
			setType('tracks');
		} else if (nav.map((item) => item.id).includes(type)) {
			setType(type);
		}
		// else {
		// 	setType('collections');
		// }
	}, [nav, setType, type]);

	return (
		<CatalogContext.Provider value={{
			setPageHandler,
			type,
			setType,
			setCollection,
			collectionId,
			albumId,
			setAlbum,
			playlistId,
			setPlaylist,
			trackId,
			setTrack,
			sort,
			setSort,
			getCurrentPageData,
		}}
		>
			{children}
		</CatalogContext.Provider>
	);
};
export default CatalogContextProvider;
