import React, {useCallback, useEffect, useState} from 'react';
import {IoMdClose} from 'react-icons/io';
import {useDispatch, useSelector} from 'react-redux';
import {Popconfirm} from 'antd';
import PlayBtn from '../../components/PlayBtn';
import {deleteTrackBasket} from '../../store/actions/basketActions';
import {usePlayer} from '../Player/context/playerContext';
import {getTimeString} from '../../utils/date-utils';

const BasketTrackListItem = ({track}) => {
	const {currentTrack} = useSelector((state) => state.player);
	const [isActive, setIsActive] = useState(false);

	const dispatch = useDispatch();
	const {
		playing,
		togglePlayPause,
		loadTrack,
		setPlay,
		play,
	} = usePlayer();

	const toggleTrackHandler = useCallback(() => {
		if (track.src && !isActive) {
			loadTrack(track);
			setPlay(true);
		} else {
			setPlay(!play);
		}
	}, [isActive, loadTrack, setPlay, track, play]);

	const deleteTrack = (code_track) => {
		if (code_track) {
			dispatch(deleteTrackBasket({code_track}));
		}
	};

	useEffect(() => {
		setIsActive(currentTrack.id === track.id);
	}, [track, currentTrack]);

	return (
		<li>
			<div className="cart__list-row align-items-center py-4">
				<div className="cart__list-col cart__list-col--name">
					<div className="d-flex align-items-center">
						<PlayBtn
							play={playing && isActive && play}
							onClick={toggleTrackHandler}
							disabled={!track.src}
						/>
						<div className="pl-4 ml-2">
							<p className="title--weight--semi pb-2 m-0">{track.name}</p>

						</div>
					</div>
				</div>
				<div className="cart__list-col cart__list-col--code">
					<p className="title--weight--medium m-0">{track.code}</p>
				</div>
				<div className="cart__list-col cart__list-col--timing">
					<p className="title--weight--medium js-page-player-song-duration-total m-0"> {getTimeString(track.length)} </p>
				</div>
				<div className="cart__list-col cart__list-col--price">
					<div className="d-flex align-items-center justify-content-between">
						<p className="title--weight--bold m-0">{new Intl.NumberFormat('ru-RU').format(track.price)} р</p>
						<Popconfirm
							title="Вы уверены, что хотите удалить трек из корзины？"
							okText="Да"
							cancelText="Нет"
							onConfirm={() => deleteTrack(track.code)}
							placement="topRight"
							okButtonProps={{
								className: 'button button--default button--success waves-light button--waves',
							}}
							cancelButtonProps={{
								className: 'button button--default button--bordered waves-light button--waves',
							}}
							className="confirm-popup"
						>
							<a
								className="cart__list-remove ml-3 d-flex align-items-center"
							>
								<IoMdClose size={22}/>
							</a>
						</Popconfirm>
					</div>
				</div>
			</div>
		</li>
	);
};

export default BasketTrackListItem;
