import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	currentTrack: {},
	playerList: [],
	isPlaying: false,
};

const playerSlice = createSlice({
	name: 'player',
	initialState,
	reducers: {
		setCurrentTrack: (state, action) => {
			state.currentTrack = action.payload;
		},
		setIsPlaying: (state, action) => {
			state.isPlaying = action.payload;
		},
		setPlayerList: (state, action) => {
			state.playerList = action.payload;
		},
	},
});

export const {setCurrentTrack, setIsPlaying, setPlayerList} = playerSlice.actions;
export default playerSlice.reducer;
