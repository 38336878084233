import React, {useEffect} from 'react';
import './Catalog.scss';
import {useDispatch, useSelector} from 'react-redux';
import {saveAs} from 'file-saver';
import {notification} from 'antd';
import CatalogFilter from './CatalogFilter';
import FilterContextProvider from './context/filterContext';
import CatalogNav from './CatalogNav';
import CatalogContextProvider from './context/catalogContext';
import CatalogContent from './CatalogContent';
import Player from '../Player/Player';
import Search from '../Search/Search';
import PlayerContextProvider from '../Player/context/playerContext';
import {getFavorite, getListened} from '../../store/actions/userListActions';
import {getSubscribe} from '../../store/actions/subscribeActions';
import {getTracksBasket} from '../../store/actions/basketActions';
import {getPaymentProfile} from '../../store/actions/userActions';
import {clearError, clearType} from '../../store/reducers/subscribeSlice';
// import CatalogPlayerSettings from './CatalogPlayerSettings';

const Catalog = () => {
	const dispatch = useDispatch();
	const {isAuth} = useSelector((state) => state.user);
	const {tracks} = useSelector((state) => state.basket);
	const {downloadType, downloadTrackId, error} = useSelector((state) => state.subscribe);

	useEffect(() => {
		dispatch(getFavorite());
		dispatch(getListened());
		dispatch(getSubscribe());
		dispatch(getPaymentProfile());
	}, [dispatch]);

	useEffect(() => {
		if (isAuth) {
			dispatch(getTracksBasket());
		}
	}, [dispatch, isAuth]);

	useEffect(() => {
		if (!error && downloadType) {
			const URL = `${window.location.origin}/download/?track_id=${downloadTrackId}&type=${downloadType}`;
			const downloadFile = new Promise(() => {
				saveAs(URL, `${downloadTrackId}.${downloadType}`);
			});
			downloadFile.then(() => {
				dispatch(clearType());
			});
		}
		if (error) {
			const promise = new Promise(() => {
				notification.error({
					message: error,
				});
			});
			promise.then(() => {
				dispatch(clearError());
			});
		}
	}, [dispatch, downloadType, error, downloadTrackId]);

	useEffect(() => {
		if (tracks && !!tracks.length) {
			const cartBtn = document.getElementById('react-head_basket');
			if (cartBtn) {
				cartBtn.setAttribute('href', '/basket/');
			}
		}
	}, [tracks]);

	return (
		<CatalogContextProvider>
			<FilterContextProvider>
				<PlayerContextProvider>
					<Search/>
					{/*<CatalogPlayerSettings />*/}
					<CatalogFilter/>
					<div className="catalog__main js-catalog-main">
						<CatalogNav/>
						<CatalogContent/>
					</div>
					<Player/>
				</PlayerContextProvider>
			</FilterContextProvider>
		</CatalogContextProvider>
	);
};

export default Catalog;
