import React from 'react';
import styled from 'styled-components';

const StyledFilterTitle = styled.p`
  position: absolute;
  top: 0;
  
`;

const FilterTitle = ({title, ...props}) => {
	return (
		<StyledFilterTitle className="text--small title--weight--medium" {...props}>
			{title}
		</StyledFilterTitle>
	);
};

export default FilterTitle;