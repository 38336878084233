import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useFilter} from './context/filterContext';

const StyledLink = styled.a`
  cursor: pointer;
`;

const CatalogFilterListItem = ({filter}) => {
	const {filterId, setFilterHandler, searches} = useFilter();
	// const [isInvolved, setIsInvolved] = useState(false);

	// useEffect(() => {
	// 	const arrChoices = !!searches.filter((item) => !!item.type.includes(filterId));
	// 	console.log(searches, filter.id, filterId)
	// 	setIsInvolved(arrChoices);
	// }, [filter, searches, filterId]);

	return (
		<li onClick={setFilterHandler(filter.id)}>
			<StyledLink
				className={`catalog__filter-link
			 ${filterId === filter.id ? 'is-active' : ''}
			 ${!!searches.find((el) => !!el.type.includes(filter.id)) ? 'is-involved' : ''}`}
				data-filter-button="catalog-filter-id--genre"
			><span>{filter.title}</span>
			</StyledLink>
		</li>
	);
};

export default CatalogFilterListItem;
