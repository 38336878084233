import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';
import post from '../../rest/postLocal';
import get from '../../rest/getLocal';
import { getPaymentProfile } from './userActions';

export const editProfile = createAsyncThunk(
	'user/paymentProfile/edit',
	async ({ profile }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await post({
				url: 'user/paymentProfile/edit',
				data: profile,
			});
			dispatch(getPaymentProfile());
			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const createProfile = createAsyncThunk(
	'user/paymentProfile/create',
	async ({ name, id_profile }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await post({
				url: 'user/paymentProfile/create',
				data: qs.stringify({
					name,
					id_profile,
				}),
			});
			dispatch(getPaymentProfile());
			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const deleteProfile = createAsyncThunk(
	'user/paymentProfile/delete',
	async ({ id }, { rejectWithValue, dispatch }) => {
		try {
			const { data } = await post({
				url: 'user/paymentProfile/delete',
				data: qs.stringify({
					id,
				}),
			});
			dispatch(getPaymentProfile());
			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getListToCreate = createAsyncThunk(
	'user/paymentProfile/getListToCreate',
	async (_, { rejectWithValue }) => {
		try {
			const { data } = await get({
				url: 'user/paymentProfile/getListToCreate',

			});
			return { data };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);
