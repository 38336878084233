import React from 'react';
import {useSelector} from 'react-redux';
import BasketLicenseListItem from './BasketLicenseListItem';

const BasketLicenseList = ({onClick}) => {
	const {licenses, activeLicense} = useSelector((state) => state.basket);
	return (
		<div className="cart__licenses">
			<div className="row">
				<div className="col-12 mb-4">
					<p className="title--weight--medium text--middle">Выберите тип лицензии</p>
				</div>
				{
					licenses.map((license) => {
						return (
							<BasketLicenseListItem
								key={license.id}
								onClick={onClick}
								license={license}
								isActive={activeLicense && activeLicense.id === license.id}
							/>
						);
					})
				}
			</div>
		</div>
	);
};

export default BasketLicenseList;
