import React, { useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IoMdClose} from 'react-icons/io';
import {Popconfirm} from 'antd';
import { deleteAllSubscribeBasket } from '../../store/actions/basketActions';

const BasketSubscribeItem = ({deleteSubscribe}) => {
	const dispatch = useDispatch();
	const {activeLicense} = useSelector((state) => state.basket);
	const clearBasketHandler = () => {
		dispatch(deleteAllSubscribeBasket());
	};
	return (
		<div className="cart__list">
			<div className="cart__list-header">
				<div className="row justify-content-between align-items-center">
					<div className="col-auto">
						<p className="title--weight--medium text--middle">Подписка</p>
					</div>
					<div className="col-auto">
						<Popconfirm
							title="Вы уверены, что хотите очистить корзину？"
							okText="Да"
							cancelText="Нет"
							onConfirm={clearBasketHandler}
							placement="topRight"
							okButtonProps={{
								className: 'button button--default button--success waves-light button--waves',
							}}
							cancelButtonProps={{
								className: 'button button--default button--bordered waves-light button--waves',
							}}
							className="confirm-popup"
						>
							<a
								className="d-inline-block text--gray text--small"
							>
								Очистить корзину
							</a>
						</Popconfirm>

					</div>
				</div>
			</div>
			<div className="cart__list-main text--small">
				<div className="cart__list-head">
					<div className="cart__list-row text--gray">
						<div className="cart__list-col cart__list-col--name">
							<p>Название</p>
						</div>
						<div className="cart__list-col cart__list-col--code">
							<p>Количество треков</p>
						</div>
						<div className="cart__list-col cart__list-col--timing">
							<p>Тип лицензии</p>
						</div>
						<div className="cart__list-col cart__list-col--price">
							<p>Цена</p>
						</div>
					</div>
				</div>
				<div className="cart__list-body text--small js-cart-list-output">

					<ul className="card__detailed-tracks js-cards-output">
						<li>
							<div className="cart__list-row align-items-center py-4">
								<div className="cart__list-col cart__list-col--name">
									<p className="title--weight--semi pb-2 m-0">{activeLicense.name}</p>
								</div>
								<div className="cart__list-col cart__list-col--code">
									<p className="title--weight--medium m-0">
										{activeLicense.count_tracks_to_download}
									</p>
								</div>
								<div className="cart__list-col cart__list-col--timing">
									<p className="title--weight--medium js-page-player-song-duration-total m-0">
										{activeLicense.type_license}
									</p>
								</div>
								<div className="cart__list-col cart__list-col--price">
									<div className="d-flex align-items-center justify-content-between">
										<p className="title--weight--bold m-0">{new Intl.NumberFormat('ru-RU').format(activeLicense.price)} р</p>
										<Popconfirm
											title="Вы уверены, что хотите удалить подписку из корзины？"
											okText="Да"
											cancelText="Нет"
											onConfirm={deleteSubscribe}
											placement="topRight"
											okButtonProps={{
												className: 'button button--default button--success waves-light button--waves',
											}}
											cancelButtonProps={{
												className: 'button button--default button--bordered waves-light button--waves',
											}}
											className="confirm-popup"
										>

										<a
											className="cart__list-remove ml-3 d-flex align-items-center"
										>
											<IoMdClose size={22}/>
										</a>
										</Popconfirm>
									</div>
								</div>
							</div>
						</li>
					</ul>

				</div>		
				</div>
		</div>
	);
};

export default BasketSubscribeItem;
