import React, {memo, useEffect, useState} from 'react';
import Select, {components} from 'react-select';
import styled from 'styled-components';
import {
	BsSortNumericUp, BsSortAlphaUp, BsSortAlphaDown, BsSortNumericDown,
	BsSortUp,
	BsSortDownAlt,
} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {useCatalog} from './context/catalogContext';
import {useFilter} from './context/filterContext';
import {clearTracks, setCatalogProperty} from '../../store/reducers/catalogSlice';

const StyledSort = styled.div`
	display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const StyledSelect = styled(Select)`
	& .react-select {
		&__indicators {
			display: none;
		}
		&__control {
			width: 200px;
			cursor: pointer;
			&:hover {
				border-color: #10db92;
			}
			&--is-focused {
				border-color: #10db92!important;
				box-shadow: 0 0 0  1px rgb(16,219, 146);
			}
			&--menu-is-open {
				box-shadow: 0 0 0  1px rgb(16,219, 146);
			}	
			&-icon {
				position: absolute;
				right: 10px;
			}	
		}
		
		&__option {
			border-bottom: 1px solid #E3E3E3;			
			&--is-selected {
				background: #F5F5F8;
				color: #000;
			}
			&:hover  {
				background: #F5F5F8;
				cursor: pointer;
			}
		}
	}
`;

const Control = ({children, ...props}) => {
	const {value} = props.getValue()[0];
	return (
		<components.Control {...props}>
				<span className="react-select__control-icon">
					{
						value === 'date_asc' && <BsSortDownAlt size={20} style={{margin: '0 5px'}}/>
					}
					{
						value === 'date_desc' && <BsSortUp size={20} style={{margin: '0 5px'}}/>
					}
					{
						value === 'name_asc' && <BsSortDownAlt size={20} style={{margin: '0 5px'}}/>
					}
					{
						value === 'name_desc' && <BsSortUp size={20} style={{margin: '0 5px'}}/>
					}
				</span>
			{children}
		</components.Control>
	);
};

const Option = ({children, value, ...props}) => {
	return (
		<components.Option {...props}>
				<span className="react-select__control-icon">
					{
						value === 'date_asc' && <BsSortDownAlt size={20} style={{margin: '0 5px'}}/>
					}
					{
						value === 'date_desc' && <BsSortUp size={20} style={{margin: '0 5px'}}/>
					}
					{
						value === 'name_asc' && <BsSortDownAlt size={20} style={{margin: '0 5px'}}/>
					}
					{
						value === 'name_desc' && <BsSortUp size={20} style={{margin: '0 5px'}}/>
					}
				</span>
			{children}
		</components.Option>
	);
};

const options = [
	{value: '', label: 'По умолчанию'},
	{value: 'name_asc', label: 'По названию'},
	{value: 'name_desc', label: 'По названию'},
	{value: 'date_asc', label: 'По дате выхода'},
	{value: 'date_desc', label: 'По дате выхода'},
];

const CatalogSort = ({className = ''}) => {
	const dispatch = useDispatch();
	const {trackSearch} = useSelector((state) => state.catalog);
	const {setSort, sort} = useCatalog();
	const {setName, name} = useFilter();
	const [value, setValue] = useState(options[0]);
	const sortHandler = ({value}) => {
		dispatch(clearTracks());
		setSort(!!value ? value : undefined);
		if (name) {
			setName('');
		}
		if (!!trackSearch.length) {
			dispatch(setCatalogProperty({property: 'trackSearch', value: []}));
		}
	};

	useEffect(() => {
		setValue(options.find((option) => option.value === sort) || options[0]);
	}, [sort]);

	return (
		<StyledSort className={`select--sorting ml-auto mt-auto text--small Select ${className}`}>

			<p className="title--weight--semi pr-4 m-0">Сортировать:</p>

			<StyledSelect
				options={options}
				onChange={sortHandler}
				defaultValue={value}
				classNamePrefix="react-select"
				components={{Control, Option}}
				value={value}
			/>

		</StyledSort>
	);
};

export default memo(CatalogSort);
