import React from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';
import {Collapse} from 'react-collapse';
import {getImagePath} from '../../utils/image-utils';

const StyledHeader = styled.header`
	.StyledHeader__info {
		overflow: hidden;	
		flex: 1 0 auto;
	}
	.StyledHeader__sort {
		position: relative;
		z-index: 999;
	}
	
`;

const CatalogAlbumDetailHeader = ({albumInfo, open}) => {
	const {poster, name, description} = albumInfo;
	const imageSrc = getImagePath(poster);
	return (
		<StyledHeader
			className="card__detailed-header"
			id="js-catalog-detailed-header"
		>
			<Collapse isOpened={open}>
				<div className="StyledHeader__info d-flex align-items-center">
					<div className="card__detailed-image">
						<div className="page__image">
							<img
								className="lazy__image lazy__image--static is-entered is-loaded"
								alt="Альтернативное описание"
								src={imageSrc}
							/>
						</div>
					</div>
					<div className="card__detailed-info">
						<h2 className="title title--regular title--weight--bold">{name}</h2>
						<p className="text--gray">{description && parse(description)}</p>
					</div>
				</div>
			</Collapse>
		</StyledHeader>

	);
};

export default CatalogAlbumDetailHeader;
