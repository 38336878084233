import {createAsyncThunk} from '@reduxjs/toolkit';
import qs from 'qs';
import post from '../../rest/postLocal';
import get from '../../rest/getLocal';

export const getFavorite = createAsyncThunk(
	'user/favorite/get',
	async (_, {rejectWithValue}) => {
		try {
			const {data} = await get({
				url: 'user/favorite/get',

			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const addFavorite = createAsyncThunk(
	'user/favorite/add',
	async ({trackId}, {rejectWithValue, dispatch}) => {
		try {
			const params = {
				track_id: trackId,
			};

			const {data} = await post({
				url: 'user/favorite/add',
				data: qs.stringify(params),
			});
			dispatch(getFavorite());
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const deleteFavorite = createAsyncThunk(
	'user/favorite/delete',
	async ({trackId}, {rejectWithValue, dispatch}) => {
		try {
			const params = {
				track_id: trackId,
			};

			const {data} = await post({
				url: 'user/favorite/delete',
				data: qs.stringify(params),
			});
			dispatch(getFavorite());
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getListened = createAsyncThunk(
	'user/listened/get',
	async (_, {rejectWithValue}) => {
		try {
			const {data} = await get({
				url: 'user/listened/get',
			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const getTracksIdsBySubscription = createAsyncThunk(
	'user/getTracksIdsBySubscription',
	async (id, {rejectWithValue}) => {
		try {
			const {data} = await get({
				url: `/getTracksIdsBySubscription/?id=${id}`,
			});
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);

export const addListened = createAsyncThunk(
	'user/listened/add',
	async ({trackId}, {rejectWithValue, dispatch}) => {
		try {
			const params = {
				track_id: trackId,
			};

			const {data} = await post({
				url: 'user/listened/add',
				data: qs.stringify(params),
			});
			dispatch(getListened());
			return {data};
		} catch (e) {
			return rejectWithValue(e.message);
		}
	},
);
