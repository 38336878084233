import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CatalogFilterListItem from './CatalogFilterListItem';
import {useFilter} from './context/filterContext';
import {useCatalog} from './context/catalogContext';
import {getTrack} from '../../store/actions/catalogActions';

const CatalogFilterList = memo(({
	setOpenFavoriteHandler, favoriteOpen, historyOpen, setOpenHistoryHandler,
}) => {
	const dispatch = useDispatch();
	const {isAuth} = useSelector((state) => state.user);
	const [currentFilters, setCurrentFilters] = useState([]);
	const {
		filters,
	} = useFilter();
	const {trackId, type} = useCatalog();
	useEffect(() => {
		if (!!trackId) {
			dispatch(getTrack({
				trackId,
			}));
		}
	}, [trackId, dispatch]);

	useEffect(() => {
		if (filters) {
			const currentFilters = filters.filter(({id}) => {
				if (type === 'collections') {
					if (id === 'bpm' || id === 'length') {
						return false;
					}
				}
				return true;
			});
			setCurrentFilters(currentFilters);
		}
	}, [type, filters]);

	return (
		<aside className="catalog__filter-main js-smooth-scroll-target">
			<ul className="catalog__filter-list js-catalog-filter--controls">
				{
					currentFilters.map((filter) => <CatalogFilterListItem key={filter.id} filter={filter}/>)
				}
			</ul>
			{
				isAuth && (
					<ul className="catalog__filter-personal js-catalog-filter--personal">
						<li>
							<div
								className={`catalog__filter-link ${historyOpen ? 'is-active' : ''}`}
								onClick={setOpenHistoryHandler}
								style={{cursor: 'pointer'}}
								data-filter-button="catalog-filter-id--history"
							>
								История
							</div>
						</li>
						<li>
							<div
								className={`catalog__filter-link ${favoriteOpen ? 'is-active' : ''}`}
								onClick={setOpenFavoriteHandler}
								style={{cursor: 'pointer'}}
								data-filter-button="catalog-filter-id--favorite"
							>
								Избранное
							</div>
						</li>
					</ul>
				)
			}
		</aside>
	);
});

export default CatalogFilterList;
