import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addTrackBasket } from '../../../store/actions/basketActions';

const useBasketButton = (auth, track) => {
	const dispatch = useDispatch();
	const addTrackToBasket = useCallback(() => {
		const regButton = document.querySelector('[data-modal-open="modal--authorization"]');
		if (!auth && regButton) {
			localStorage.setItem('authTrack', JSON.stringify(track));
			regButton.click();
		} else {
			dispatch(addTrackBasket({ code_track: track.code }));
		}
	}, [auth, dispatch, track]);

	return [addTrackToBasket];
};

export default useBasketButton;
