import React from 'react';
import {useDispatch} from 'react-redux';
import CatalogTracksItem from './CatalogTracksItem';
import {setPopover} from '../../store/reducers/userListSlice';

const CatalogTrackList = ({tracks, isLoading, hideInfo}) => {
	const dispatch = useDispatch();
	const scrollHandler = () => {
		dispatch(setPopover(null));
	};
	return (
		<div onScroll={scrollHandler}>
			{
				!tracks.length && !isLoading
					? <div className="catalog__content--detailed">Треки не найдены...</div>
					: (
						<>
							{
								!!tracks.length && tracks.map((track, idx) => {
									return (
										<CatalogTracksItem
											key={track.id}
											track={track}
											num={idx + 1}
											hideInfo={hideInfo}
										/>
									);
								})
							}
						</>
					)
			}

		</div>
	);
};

export default CatalogTrackList;
