import React from 'react';
import {FaVolumeUp} from 'react-icons/fa';
import Slider from 'rc-slider';
import styled from 'styled-components';

const StyledVolume = styled.div`
  position: absolute;
  width: 34px;
  height: 96%;
  display: flex;
  justify-content: center;
  transform: translateX(-40%);
  top: 5px;
`;

const PlayerVolume = ({volume, setVolume, isActive}) => {
	return (
		<>
			<a
				className={`player__interaction-button waves-effect waves-button js-page-player-volume ${isActive ? 'js-dropdown-button' : ''}`}
				href="#"
			>
				<FaVolumeUp size={24} color={isActive ? '#fff' : '#0B9A66'}/>
			</a>
			<div
				className={`player__interaction-content player__volume ${isActive ? 'js-dropdown-content' : ''}`}
			>
				<div className="player__volume-track js-page-player-song-volume-track">
					<div
						className="player__volume-progress js-page-player-song-volume-progress"
						style={{height: `${volume * 100}%`}}
					/>
					<div
						className="player__volume-bar js-page-player-song-volume-bar"
						style={{bottom: `${volume * 100}px`}}
					/>
					{
						!!isActive
						&& (
							<StyledVolume>
								<Slider
									vertical
									min={0.0}
									max={1}
									step={0.1}
									value={volume}
									onChange={setVolume}
									className="Catalog__volume-bar_slider"
									trackStyle={{
										height: '80px',
										background: 'transparent',
										borderRadius: 0,
									}}
									railStyle={{
										height: '80px',
										background: 'transparent',
									}}
									handleStyle={{
										background: 'transparent',
										height: '20px',
										width: '20px',
										border: 'none',
										borderRadius: '0',
									}}
								/>
								<FaVolumeUp
									size={24}
									color="#10DB92"
									style={{
										position: 'relative',
										top: 131,
										left: -8,
										transform: 'scale(1.1)',
									}}
								/>
							</StyledVolume>
						)
					}
				</div>
			</div>
		</>
	);
};

export default PlayerVolume;
