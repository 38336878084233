import React from 'react';
import SearchPortal from './SearchPortal';
import SearchForm from './SearchForm';

const Search = () => {
	return (
		<SearchPortal>
			<SearchForm/>
		</SearchPortal>
	);
};

export default Search;
