import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {SEARCH_ID} from '../../../config';

const searchRoot = document.getElementById(SEARCH_ID);

class SearchPortal extends Component {
	render() {
		const {children} = this.props;
		if (!searchRoot) {
			return null;
		}
		return ReactDOM.createPortal(
			children,
			searchRoot,
		);
	}
}

export default SearchPortal;
