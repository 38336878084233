import React, {forwardRef} from 'react';
import {GrClose} from 'react-icons/gr';

const Modal = forwardRef(({onClose, title, children}, ref) => {
	return (
		<div
			ref={ref}
			className="page__modal page__modal--profile-name"
		>
			<div className="page__modal-wrapper">
				<div className="page__modal-overlay" onClick={onClose}/>
				<div className="page__modal-container">
					<button
						type="button"
						className="page__modal-close p-2"
						onClick={onClose}
					>
						<GrClose size={30}/>
					</button>
					<div className="d-flex flex-column align-items-center">
						<h2 className="title--weight--bold text--extra-large text--success">{title}</h2>
						{children}
					</div>
				</div>
			</div>
		</div>
	);
});

export default Modal;
